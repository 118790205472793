<template>
    <div class="shiyan">
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.title" placeholder="计划名称" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>

                    <el-button type="primary" style="float: right;"  @click="handlePlan">创建问卷头</el-button>


            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
<!--                <el-table-column type="selection" width="55" align="center"></el-table-column>-->
<!--                <el-table-column prop="experiment_code" label="试验编号"></el-table-column>-->
                <el-table-column prop="questionnaireName" label="问卷名称"></el-table-column>
                <el-table-column prop="questionnaireDescribe" label="问卷介绍"></el-table-column>
                <el-table-column prop="questionnaireType" label="问卷类型"></el-table-column>
                <el-table-column prop="status" label="问卷状态">
                    <template slot-scope="scope">
                        {{scope.row.status == '0' ? '草稿' : scope.row.status == '1'  ? '已提交' : ''}}
                    </template>
                </el-table-column>
                <el-table-column prop="questionnaireType" label="操作">
                        <template slot-scope="scope">
                            <el-link style="color: dodgerblue;" @click="handleQuestion(scope.row)">问题管理</el-link>
                            <span v-if="scope.row.status == '0' || !scope.row.status">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            <el-link v-if="scope.row.status == '0' || !scope.row.status" style="color: orangered;" @click="handleSubmit(scope.row)">

                                提交
                            </el-link>
                        </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.page"
                        :page-size="query.limit"
                        :total="total"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>


<!--            <el-upload-->
<!--                    class="upload-demo"-->
<!--                    action="http://192.168.0.173:40071/web/experimentalSubject/upload/"-->
<!--                    :on-preview="handlePreview"-->
<!--                    :on-remove="handleRemove"-->
<!--                    :before-remove="beforeRemove"-->
<!--                    multiple-->
<!--                    :limit="3"-->
<!--                    :on-exceed="handleExceed"-->
<!--                    :file-list="fileList">-->
<!--                <el-button size="small" type="primary">点击上传</el-button>-->
<!--            </el-upload>-->
        </div>


        <!-- 导入面谈计划-->
        <el-dialog title="创建问卷头" :visible.sync="planVisible" width="500px">

               <div style="padding:10px;">
                   <el-form inline>
                       <el-form-item label="标题">
                           <el-input size="mini"  v-model="addForm.questionnaireName"></el-input>
                       </el-form-item>
                       <el-form-item label="介绍">
                           <el-input size="mini" type="textarea"  v-model="addForm.questionnaireDescribe"></el-input>
                       </el-form-item>
                   </el-form>
               </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="planVisible = false">取 消</el-button>
                <el-button type="primary" @click="addPlan">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="问题管理" :visible.sync="questionVisible" width="850px">

              <el-row>
                  <el-col style="padding-bottom: 10px;">
                      <el-button type="primary" size="mini" style="float: right;" @click="handleQuestionItem">添加问题</el-button>
                  </el-col>
                  <el-col style="height: 400px;overflow: auto;">
                      <el-table
                              :data="questionList"
                              border
                              class="table"
                              ref="multipleTable"

                              header-cell-class-name="table-header"
                              @selection-change="handleSelectionChange"
                      >
                          <!--                <el-table-column type="selection" width="55" align="center"></el-table-column>-->
                          <!--                <el-table-column prop="experiment_code" label="试验编号"></el-table-column>-->
                          <el-table-column prop="title" label="名称"></el-table-column>
                          <el-table-column prop="remark" label="描述"></el-table-column>
                          <el-table-column prop="mold" label="类型">
                              <template slot-scope="scope">
                                 {{scope.row.mold == 1 ? '单选': '多选'}}
                              </template>
                          </el-table-column>
                          <el-table-column prop="required" label="是否必填">
                              <template slot-scope="scope">
                                  {{scope.row.required == 1 ? '是': '否'}}
                              </template>
                          </el-table-column>
                          <el-table-column prop="answer" label="答案">
                              <template slot-scope="scope">
                                   <div v-for="(item, index) in scope.row.answer">
                                        {{item.choose}}.{{item.reply}}: {{item.score}}
                                   </div>
                              </template>
                          </el-table-column>
                          <el-table-column prop="questionnaireType" label="操作">
                              <template slot-scope="scope">
                                  <el-button size="mini" type="text" @click="editQuestion(scope.row)">编辑</el-button>
                                  <el-button size="mini" type="text" style="color: orangered" @click="delQuestion(scope.row, scope.$index)">删除</el-button>
                              </template>
                          </el-table-column>
                      </el-table>
                  </el-col>
              </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="questionVisible = false">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog title="问题管理" :visible.sync="questionItemVisible" width="800px">
                <el-form inline>
                    <el-row>
                        <el-col  >
                            <el-form-item label="名称">
                                <el-input size="mini" v-model="questionItemForm.title"></el-input>
                            </el-form-item>
                            <el-form-item label="描述">
                                <el-input size="mini" v-model="questionItemForm.remark"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col >
                            <el-form-item label="问题类型">

                                <el-select  size="mini" v-model="questionItemForm.mold">
                                    <el-option label="单选" :value="1"></el-option>
                                    <el-option label="多选" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="是否必填">

                                <el-select  size="mini" v-model="questionItemForm.required">
                                    <el-option label="是" :value="1"></el-option>
                                    <el-option label="否" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-divider content-position="left">答案</el-divider>
                        </el-col>



                        <el-col v-if="questionItemForm.answer.length === 0" style="font-size: 24px;">
                            <i class="el-icon-circle-plus-outline" @click="addAnswer"></i>
                        </el-col>
                        <el-col v-else v-for="(item, index) in questionItemForm.answer" :key="index" >
                            <el-form-item label="选项">
                                <el-input size="mini" v-model="item.choose"></el-input>
                            </el-form-item>
                            <el-form-item label="名称">
                                <el-input size="mini" v-model="item.reply"></el-input>
                            </el-form-item>
                            <el-form-item label="分数">
                                <el-input size="mini" v-model="item.score"></el-input>
                            </el-form-item>
                            <span style="font-size: 24px;">
                                <i class="el-icon-circle-plus-outline" @click="addAnswer(index)"></i>
                                <i class="el-icon-remove-outline" @click="removeAnswer(index)"></i>
                            </span>
                        </el-col>
                    </el-row>
                </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="questionItemVisible = false">取 消</el-button>
                <el-button type="primary" @click="addQuestion">确 定</el-button>
            </span>
        </el-dialog>






    </div>
</template>

<script>

    import {
        questionInsertData,
        questionDeleteById,
        getStudentByPlanId,
        published,
        questionGetById,
        questionGetList,
        questionnaireInformationUpdateData,
        questionInsertQuestionnaire,
        questionGetPageList} from '@/apis/project/index';
    import util from '@/utils/util';
    import {questionupdateData} from '../../../apis/project';
    export default {
        name: 'medical',
        components: {
        },
        computed: {
            projectToNode () {
                console.log(this.form);
                return this.form.experimentalStageDOList.filter(item => {
                    return item.stageName.trim() !== '' && item.startDay >= 0  && item.endDay >= 0;
                })
            },
        },
        data() {
            return {
                addForm: {
                    questionnaireName: '',
                    questionnaireDescribe: '',
                    questionnaireType: '1',
                    status: '0'
                },
                questionItemForm: {
                    title: '',
                    remark: '',
                    mold: 1,
                    required: 1,
                    surveyId: '',
                    answer: [],
                },
                nowSchoolYear: new Date().getFullYear(),
                stuPlanVisible: false,
                studentPlanList: [],
                username: sessionStorage.getItem('username'),
                stuForm: {
                    limit: 1000,
                    page: 1
                },
                schoolYearList: [],
                selectStudentList: [],
                studentList: [],
                importSchoolYear: '',
                fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
                nodeName: '',
                projectList: [],
                fsValue: '',
                query: {

                    "limit": 10,
                    "page": 1,
                    "questionnaireType": '1'
                },
                medicalIndex: undefined,
                total: 0,
                tableData: [
                ],

                patientData: [],
                showPatientData: [],
                showFillData: [],
                multipleSelection: [],
                delList: [],
                editVisible: false,
                stuVisible: false,
                planVisible: false,
                questionVisible: false,
                questionItemVisible: false,
                addPatientVisible: false,
                showPatientVisible: false,
                editFsqVisible: false,
                form: this.initFORM(),
                formFsq: {},
                id: -1,
                items: [],
                currentItem: [],
                visible: false,
                isShow: false,
                showFillVisible: false,
                questionId: '',
                questionList: [],
                questionType: '1', // 新增还是修改
            };
        },
        created() {
            this.getData();

        },
        mounted() {

        },
        methods: {
            addQuestion() {
                let APIS = [{}, {api: questionInsertData, type: '添加'}, {api: questionupdateData, type: '修改'}]
                APIS[this.questionType].api(this.questionItemForm).then(
                    res => {
                        console.log(res);
                        if (res.data.code === 0) {
                            if (this.questionType === '1') {
                                this.questionList.push(this.questionItemForm);
                            }
                            this.$message.success(APIS[this.questionType].type + '问题成功');
                            this.questionItemVisible = false;
                        } else {
                            this.$message.error(APIS[this.questionType].type + '问题失败');
                        }
                    }
                )
            },
            addAnswer(index) {

                if (index || index == 0) {
                    this.questionItemForm.answer.splice(index + 1, 0 , {choose: '', reply: '', score: '', questionId: this.questionItemForm.id})
                } else {
                    this.questionItemForm.answer.push({choose: '', reply: '', score: '', questionId: this.questionItemForm.id})
                }

            },
            removeAnswer(index) {
                    this.$delete(this.questionItemForm.answer, index);
            },
            getPlanDetail(row ,index) {

                let params = {
                    planId:row.id,
                    tutorCode: sessionStorage.getItem('username') === 'admin' ? null : sessionStorage.getItem('username')
                }

                getStudentByPlanId(params).then(res => {
                    this.stuPlanVisible = true;
                    this.studentPlanList = res.data.data;
                })
            },




            // 导入面谈计划
            addPlan() {
                questionInsertQuestionnaire(this.addForm).then(res =>{
                    if (res.data.code === 0) {
                        this.$message.success('问卷头创建成功');
                        this.planVisible = false;
                        this.addForm.questionnaireName= '';
                        this.getData();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },


            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'page', val);
                this.getData();
            },

            // 分页导航
            handleSelectionPatientChange(val) {
                this.multipleSelection = val;
                console.log(val);
            },
            //

            // 选择周期类型
            selectProjectType(item) {
                console.log(item, this.form);
                if (item.projectType === '1') {
                    this.$set(item, 'cycle', 0);
                } else {
                    this.$set(item, 'cycle', '');
                }

            },




            getProjectList () {
                let params = {
                    limit: 10,
                    page: 1
                }

            },

            getProjectExperimentalList (id) {
                let params = {
                    experimentalId: id,
                    limit: 10,
                    page: 1
                }
                getList(params).then(res => {
                  //  this.projectList = res.data.data;
                    if (res && res.data.code === 0) {
                        this.patientData = res.data.data;
                        console.log(this.patientData)
                    } else {
                        this.$message.error('查询用户列表失败');
                    }
                });
            },


            // 删除项目
            removeProjectChild(index) {
                console.log(this.form.experimentalProjectAOList[index]);
                 //   this.$delete(this.form.experimentalProjectAOList, index);
                this.$set(this.form.experimentalProjectAOList[index], 'state', 1);


            },
            handleAdd() {
                this.type = 2;
                this.isShow = false;
                this.form = this.initFORM();
                this.editVisible = true;
                console.log(this.form);
            },

            handleStu() {

                this.stuVisible = true;
                console.log(this.form);
            },
            handlePlan() {

                this.planVisible = true;

            },
            handleQuestion(row) {

                questionGetList({surveyId: row.id}).then(res => {
                    if (res.data.code === 0) {
                        this.questionId = row.id;
                        this.questionList = res.data.data;
                        this.questionItemForm.surveyId = row.id;
                        this.questionVisible = true;

                    }
                })


            },

            handleSubmit(row) {
                console.log(row);
                this.$confirm(`确定提交问卷吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    row.status = '1';
                    questionnaireInformationUpdateData(row).then(res => {
                        if (res.data.code === 0) {
                           this.$message.success('提交成功');

                        } else {
                            this.$message.error('提交失败');
                        }
                    })


                });


            },

            handleQuestionItem() {
                this.questionType = '1';
                this.questionItemForm = {
                    title: '',
                        remark: '',
                        mold: 1,
                        required: 1,
                        surveyId: this.questionId,
                        answer: [],
                }
                this.questionItemVisible = true;

            },

            delQuestion(row, index) {

                this.$confirm("确定删除吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    questionDeleteById(row.id).then(res => {
                        if (res.data.code === 0) {
                            this.$message.success('删除成功');
                            this.$delete(this.questionList, index);

                        } else {
                            this.$message.error('删除失败');
                        }
                    })
                });


            },
            editQuestion(row) {
                this.questionType = '2';
                this.questionItemForm = row;
                this.questionItemVisible = true;
            },

            select(item, path) {
                this.medicalIndex = item;
                console.log(item);
            },
            show (row) {
                this.currentItem = row;
                this.currentItem.planType = this.currentItem.planType.toString();
                this.form = row;


              //  this.editVisible = true;
               // this.isShow = true;
               // this.getByExperimentInfo(row.experimentCode);

            },
            edit (row) {
                this.currentItem = row;
                this.form = row;
                this.type = 1;
                this.isShow = false;
                this.editVisible = true;
              //  this.getByExperimentInfo(row.experimentCode);

            },

            addPatient (row) {
                this.currentItem = row;
                this.form = row;
                this.type = 1;
                this.multipleSelection = [];
                this.addPatientVisible = true;

            },

            showPatient (row) {
                this.currentItem = row;
                this.showPatientData = row.userInfromationDOList;
                this.showPatientVisible = true;

            },
            showFill (row, index) {
                console.log(row, this.currentItem);
                let params = {
                    experimentalId: this.currentItem.id,
                    unionId: row.unionId
                }
                getFillPlan(params).then( res => {
                   if (res.data.code === 0) {
                       this.showFillData = res.data.data;
                   } else {
                       this.$message.error(res.data.msg);
                   }
                    this.showFillVisible = true;
                });


            },
            // 试验关联用户
            addPatientData() {
                this.currentItem.userInfromationDOList = this.multipleSelection;
                this.currentItem.enrollmentTime = util.formatDate.format(new Date());
                registerSingleSubject(this.currentItem).then(res => {
                    if (res.data.code === 0) {
                        this.$message.success('关联用户成功');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.addPatientVisible = false;
                });
            },
            editFsq (row) {
                this.formFsq = row;
                this.editFsqVisible = true;

            },

            // 获取 easy-mock 的模拟数据
            getData() {
                questionGetPageList(this.query).then( res => {
                    if (res && res.data.code === 0) {
                        this.tableData = res.data.data.content;
                        this.total = res.data.data.total;

                    } else {
                        this.$message.error('查询失败');
                    }
                });
            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'page', 1);
                this.getData();
            },
            // 删除操作
            handleDelete(row, index) {
                // 二次确认删除
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                })
                    .then(() => {
                        let params = {
                            experimentCode: row.experimentCode
                        }
                        experimentalDelete(params).then( res => {
                            if (res.data.code === 0) {
                                this.$message.success('删除成功');
                                this.getData();
                            } else {
                                this.$message.success('删除失败');
                            }
                        });
                    })
                    .catch(() => {});
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log(val);
            },
            delAllSelection() {
                const length = this.multipleSelection.length;
                let str = '';
                this.delList = this.delList.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].name + ' ';
                }
                this.$message.error(`删除了${str}`);
                this.multipleSelection = [];
            },
            // 编辑操作
            handleEdit(index, row) {
                this.idx = index;
                this.form = row;
                this.editVisible = true;
                console.log(row);
                this.getProjectExperimentalList(row.id);
            },
            // 保存编辑
            saveEdit() {
                this.editVisible = false;
                this.$set(this.tableData, this.idx, this.form);
            },

            // 导出excel
             exportExcel() {
                 console.log(this.currentItem.experimentName.replace('/', ''));
                 experimentalSubjectDownload(this.currentItem.experimentCode, this.currentItem.experimentName.replace('/', ''));
                 // experimentalSubjectDownload(this.currentItem.experimentCode).then(res => {
                 //     if (res.data) {
                 //         var a = document.createElement('a');
                 //         var url = window.URL.createObjectURL(new Blob([res.data],
                 //             { type: ( "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")}));
                 //         a.href = url;
                 //         a.download = this.currentItem.experimentName || 'file';
                 //         a.click();
                 //         window.URL.revokeObjectURL(url);
                 //
                 //     }
                 // })
             },

            // 添加
            addEdit() {


                // this.form.experimentalStageDOList.forEach((value, index, arr) => {
                //     value['stageOrder'] = index;
                // });
              //  this.editVisible = false;
                published(this.form).then( res => {
                    if (res.data && res.data.code === 0) {
                        this.$message.success('创建计划成功');
                        this.editVisible = false;
                        this.getData();
                    } else {
                        this.$message.error('创建计划失败');
                    }
                }, err => {
                    this.$message.error('创建计划失败');
                });

            },



            // 验证form
            validateForm () {

                if (!this.form.experimentName) {
                     this.$message.error('请输入组会名称');
                    return false;
                }
                if (!this.form.unitName) {
                     this.$message.error('请输入单位名称');
                    return false;
                }
                if (!this.form.unitCode) {
                     this.$message.error('请输入单位编码');
                    return false;
                }
                if (!this.form.startTime) {
                     this.$message.error('请选择试验开始时间');
                    return false;
                }
                if (!this.form.endTime) {
                     this.$message.error('请选择试验结束时间');
                    return false;
                }

                for (let item of this.form.experimentalStageDOList) {
                    console.log(item);
                    if (!item['stageName']) {
                        this.$message.error('请输入节点名称');
                        return false;
                    }
                    if (!item['startTime']) {
                        this.$message.error('请选择节点起始时间');
                        return false;
                    }
                    if (!item['endTime']) {
                        this.$message.error('请选择节点结束时间');
                        return false;
                    }
                }

                for (let item of this.form.experimentalProjectAOList) {
                    console.log(item);
                    if (!item['projectId']) {
                        this.$message.error('请输入项目名称');
                        return false;
                    }
                    if (item['projectType'] === '1') {


                        if (item['nodeList'].length === 0) {
                            this.$message.error('请选择节点');
                            return false;
                        }

                    } else {
                        if (!item['visitStartTime']) {
                            this.$message.error('请选择项目起始时间');
                            return false;
                        }
                        if (!item['visitEndTime']) {
                            this.$message.error('请选择项目结束时间');
                            return false;
                        }
                    }
                }




                return true;
            },


             initFORM () {
                return {

                    "schoolYear": "",
                    "strDate": "",
                    "endDate": "",
                }
             },

            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            }


        }
    }
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .table {
        width: 100%;
        font-size: 14px;
    }
    .red {
        color: #ff0000;
    }
    .mr10 {
        margin-right: 10px;
    }
    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .shiyan /deep/ .el-input__inner:focus {
        background: #409EFF;
        color: #ffffff;
        border-top-color: #409EFF;
    }

    .shiyan /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }

    .row-bottom {
        border-bottom: 1px solid #eee;
        padding-top: 10px;
    }

    .selectNode /deep/ .el-select.el-select--mini {
        width: 300px;
    }

    /deep/ .el-form-item.el-form-item--small {
        width: 100%;
    }

    /deep/ .el-form-item__content {
        width: 80%;
    }


</style>