<template>
    <div class="shiyan">
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.experimentCode" placeholder="试验编号" class="handle-input mr10"></el-input>
                <el-input v-model="query.experimentName" placeholder="组会名称" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
<!--                <el-button type="primary" style="float: right;"  @click="handleAdd">添加组会</el-button>-->
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
                <!--                <el-table-column type="selection" width="55" align="center"></el-table-column>-->
                <!--                <el-table-column prop="experiment_code" label="试验编号"></el-table-column>-->
                <el-table-column prop="experimentName" label="组会名称"></el-table-column>
                <el-table-column prop="startTime" label="开始时间">
                    <template slot-scope="scope">
                        {{scope.row.startTime.split(' ')[0]}}
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="结束时间">
                    <template slot-scope="scope">
                        {{scope.row.endTime.split(' ')[0]}}
                    </template>
                </el-table-column>
                <el-table-column prop="experimentState" label="组会状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.experimentState == 0">草稿</span>
                        <span v-else-if="scope.row.experimentState == 1">完成</span>
                        <span v-else-if="scope.row.experimentState == 2">开始试验</span>
                        <span v-else-if="scope.row.experimentState == 3">已结束</span>
                    </template>
                </el-table-column>
                <el-table-column prop="unitName" label="单位名称"></el-table-column>
                <el-table-column prop="unitCode" label="单位编码"></el-table-column>
                <!--                <el-table-column prop="createtime" label="数据创建时间"></el-table-column>-->
                <!--                <el-table-column prop="createuser" label="数据创建者"></el-table-column>-->
                <!--                <el-table-column prop="updatetime" label="数据修改时间"></el-table-column>-->
                <!--                <el-table-column prop="updateuser" label="数据修改者"></el-table-column>-->


                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-document-add"
                                @click="addPatient(scope.row, scope.$index)"
                        >添加患者</el-button>
                        <el-button
                                type="text"
                                icon="el-icon-document-add"
                                @click="showPatient(scope.row, scope.$index)"
                        >患者列表</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.page"
                        :page-size="query.limit"
                        :total="total"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 添加组会患者 -->
        <el-dialog title="添加患者" :visible.sync="addPatientVisible" width="700px">
            <div style="overflow: auto;">
                <el-table
                        style="max-height: 500px;overflow: auto;"
                        :data="patientData"
                        border
                        class="table"
                        ref="multiplePatientTable"
                        @selection-change="handleSelectionPatientChange"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column prop="nickName" label="微信名称"></el-table-column>
                    <el-table-column prop="age" label="年龄"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                    <el-table-column prop="sex" label="性别"></el-table-column>
                    <el-table-column prop="birthday" label="出生日期"></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addPatientVisible = false">取 消</el-button>
                <el-button type="primary" @click="addPatientData">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 查看试验患者 -->
        <el-dialog title="患者列表" :visible.sync="showPatientVisible" width="700px">
            <div style="overflow: auto;">
                <el-table
                        style="max-height: 500px;overflow: auto;"
                        :data="showPatientData"
                        border
                        class="table"

                >
                    <el-table-column prop="nickName" label="微信名称"></el-table-column>
                    <el-table-column prop="age" label="年龄"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                    <el-table-column prop="sex" label="性别"></el-table-column>
                    <el-table-column prop="birthday" label="出生日期"></el-table-column>
                    <el-table-column  label="操作" width="150">
                        <template slot-scope="scope" >
                            <el-button
                                    type="text"
                                    icon="el-icon-document-add"
                                    @click="showFill(scope.row, scope.$index)"
                            >填报记录</el-button>
                            <el-button
                                    type="text"
                                    icon="el-icon-download"
                                    @click="exportExcel(scope.row, scope.$index)"
                            >导出</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPatientVisible = false">取 消</el-button>
            </span>
        </el-dialog>


        <!-- 试验患者填报记录 -->
        <el-dialog title="填报记录" :visible.sync="showFillVisible"  width="700px">
            <div style="overflow: auto;">
                <el-table
                        style="max-height: 500px;overflow: auto;"
                        :data="showFillData"
                        border
                        @cell-click="handleFillRecord"
                        class="table fill-table"

                >
                    <el-table-column prop="projectName" label="项目名称"></el-table-column>
                    <el-table-column prop="unFillPlan" label="未填报">
                        <template slot-scope="scope">
                            {{scope.row.unFillPlan ? scope.row.unFillPlan : 0}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="filledPlan" label="已填报">
                        <template slot-scope="scope">
                            {{scope.row.filledPlan ? scope.row.filledPlan : 0}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="missedPlan" label="漏填">
                        <template slot-scope="scope">
                            {{scope.row.missedPlan ? scope.row.missedPlan : 0}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeOutPlan" label="超时未填">
                        <template slot-scope="scope">
                            {{scope.row.timeOutPlan ? scope.row.timeOutPlan : 0}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalPlan" label="合计"></el-table-column>

                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showFillVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 试验患者填报记录详情 -->
        <el-dialog title="填报记录详情" :visible.sync="showFillDetailsVisible"  width="700px">
            <div style="overflow: auto;">
                <el-table
                        style="max-height: 500px;overflow: auto;"
                        :data="showFillDetailsData"
                        border
                        @row-click="handleFillProjectRecord"
                        class="table fill-table"

                >

                    <el-table-column prop="projectName" label="项目名称">
                    </el-table-column>
                    <el-table-column prop="cycleRecord" label="填报次数">
                    </el-table-column>
                    <el-table-column prop="pushEndTime" label="开始日期">
                    </el-table-column>
                    <el-table-column prop="pushStartTime" label="结束日期">
                    </el-table-column>
                    <el-table-column prop="submitName" label="填报状态">
                    </el-table-column>

                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showFillDetailsVisible = false">取 消</el-button>
            </span>
        </el-dialog>


        <!-- 试验患者填报项目记录详情 -->
        <el-dialog title="" :visible.sync="showFillProjectDetailsVisible"  width="700px">
            <div style="overflow: auto;">
                <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="左手血压收缩压" v-if="showFillProjectDetailsData.lsystolicPressure">
                        <span>{{ showFillProjectDetailsData.lsystolicPressure }}</span>
                    </el-form-item>
                    <el-form-item label="左手血压舒张压" v-if="showFillProjectDetailsData.ldiastolicPressure">
                        <span>{{ showFillProjectDetailsData.ldiastolicPressure }}</span>
                    </el-form-item>
                    <el-form-item label="右手血压收缩压" v-if="showFillProjectDetailsData.rsystolicPressure">
                        <span>{{ showFillProjectDetailsData.rsystolicPressure }}</span>
                    </el-form-item>
                    <el-form-item label="右手血压舒张压" v-if="showFillProjectDetailsData.rdiastolicPressure">
                        <span>{{ showFillProjectDetailsData.rdiastolicPressure }}</span>
                    </el-form-item>

                    <el-form-item label="谷类和薯类摄入量"  v-if="showFillProjectDetailsData.grainPotato">
                        <span>{{ showFillProjectDetailsData.grainPotato }}克</span>
                    </el-form-item>
                    <el-form-item label="蔬菜摄入量"  v-if="showFillProjectDetailsData.vegetables">
                        <span>{{ showFillProjectDetailsData.vegetables }}克</span>
                    </el-form-item>
                    <el-form-item label="水果摄入量"  v-if="showFillProjectDetailsData.fruits">
                        <span>{{ showFillProjectDetailsData.fruits }}克</span>
                    </el-form-item>
                    <el-form-item label="鱼虾类摄入量"  v-if="showFillProjectDetailsData.fishShrimp">
                        <span>{{ showFillProjectDetailsData.fishShrimp }}克</span>
                    </el-form-item>
                    <el-form-item label="禽肉类摄入量"  v-if="showFillProjectDetailsData.poultry">
                        <span>{{ showFillProjectDetailsData.poultry }}克</span>
                    </el-form-item>
                    <el-form-item label="鸡蛋摄入量"  v-if="showFillProjectDetailsData.egg">
                        <span>{{ showFillProjectDetailsData.egg }}个</span>
                    </el-form-item>
                    <el-form-item label="豆类及其制品摄入量"  v-if="showFillProjectDetailsData.beanProducts">
                        <span>{{ showFillProjectDetailsData.beanProducts }}克</span>
                    </el-form-item>
                    <el-form-item label="坚果摄入量"  v-if="showFillProjectDetailsData.nut">
                        <span>{{ showFillProjectDetailsData.nut }}克</span>
                    </el-form-item>
                    <el-form-item label="奶类及乳制品摄入量"  v-if="showFillProjectDetailsData.dairyProducts">
                        <span>{{ showFillProjectDetailsData.dairyProducts }}克</span>
                    </el-form-item>
                    <el-form-item label="盐摄入量"  v-if="showFillProjectDetailsData.saltContent">
                        <span>{{ showFillProjectDetailsData.saltContent }}克</span>
                    </el-form-item>
                    <el-form-item label="油摄入量"  v-if="showFillProjectDetailsData.plantOil">
                        <span>{{ showFillProjectDetailsData.plantOil }}克</span>
                    </el-form-item>
                    <el-form-item label="茶叶摄入量"  v-if="showFillProjectDetailsData.tea">
                        <span>{{ showFillProjectDetailsData.tea }}克</span>
                    </el-form-item>
                    <el-form-item label="睡眠时间"  v-if="showFillProjectDetailsData.sleepTime">
                        <span>{{ showFillProjectDetailsData.sleepTime }}小时</span>
                    </el-form-item>
                    <el-form-item label="是否存在睡眠呼吸暂停"  v-if="showFillProjectDetailsData.apnea">
                        <span>{{showFillProjectDetailsData.apnea == '1' ? '是' : '否'}}</span>
                    </el-form-item>
                    <el-form-item label="是否有接受家庭持续正压通气治疗"  v-if="showFillProjectDetailsData.ppvt">
                        <span>{{showFillProjectDetailsData.ppvt == '1' ? '是' : '否'}}</span>
                    </el-form-item>


                    <el-form-item label="运动习惯"  v-if="showFillProjectDetailsData.motionSituation">
                        <span>{{showFillProjectDetailsData.motionSituation == '0' ? '经常有体育锻炼' : showFillProjectDetailsData.motionSituation == '1' ? '缺乏体育锻炼' : '暂无数据'}}</span>
                    </el-form-item>



                    <el-form-item label="服用灯盏生脉胶囊"  v-if="showFillProjectDetailsData.dzsmjnDosage">
                        <span>{{ showFillProjectDetailsData.dzsmjnDosage }}粒</span>
                    </el-form-item>

                    <el-form-item label="服用阿司匹林片数"  v-if="showFillProjectDetailsData.aspirinDosage">
                        <span>{{ showFillProjectDetailsData.aspirinDosage }}片</span>
                    </el-form-item>

                    <el-form-item label="服用氯吡格雷(波立维)片数"  v-if="showFillProjectDetailsData.clopidogrelDosage">
                        <span>{{ showFillProjectDetailsData.clopidogrelDosage }}片</span>
                    </el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showFillProjectDetailsVisible = false">取 消</el-button>
            </span>
        </el-dialog>



    </div>
</template>

<script>
    import {getSubjectList, getByExperimentCode, getDetailList, getProjectDetail, getExperimentCodeStage, insertData, experimentalDelete, getListBySubject, registerSingleSubject, getFillPlan, fillInPlanDownload} from '@/apis/Experimental/index';
    import {
        getPageList,
        getUserInfo,

        getList} from '@/apis/project/index';
    import util from '@/utils/util';
    export default {
        name: 'medical',
        components: {
        },
        computed: {
            projectToNode () {
                console.log(this.form);
                return this.form.experimentalStageDOList.filter(item => {
                    return item.stageName.trim() !== '' && item.startDay >= 0  && item.endDay >= 0;
                })
            }
        },
        data() {
            return {
                nodeName: '',
                projectList: [],
                fsValue: '',
                query: {
                    "experimentCode": "",
                    "experimentName": "",
                    "experimentState": "",
                    "id": "",
                    "limit": 10,
                    "page": 1,
                    "state": 0,
                    "unitCode": "",
                    "unitName": "",
                },
                medicalIndex: undefined,
                total: 0,
                tableData: [
                ],
                patientData: [],
                showPatientData: [],
                showFillData: [],
                showFillDetailsData: [],
                showFillProjectDetailsData: {},
                multipleSelection: [],
                delList: [],
                editVisible: false,
                addPatientVisible: false,
                showPatientVisible: false,
                editFsqVisible: false,
                form: this.initFORM(),
                formFsq: {},
                id: -1,
                items: [],
                currentItem: [],
                currentUserItem: [],
                bz: undefined,
                sort: undefined,
                dialog: undefined,
                formData: undefined,
                type: undefined,
                visible: false,
                isShow: false,
                showFillVisible: false,
                showFillDetailsVisible: false,
                showFillProjectDetailsVisible: false

            };
        },
        created() {
            this.getData();
            this.getProjectList();
        },
        mounted() {

        },
        methods: {

            // 填报记录点击
            handleFillRecord(row, column, event, cell) {
                console.log(row[column['property']], column['property'], this.currentUserItem);
                let submitState = null;
                if (column['property'] === 'unFillPlan') {
                 submitState = 0;
                } else if (column['property'] === 'filledPlan') {
                 submitState = 1;
                } else if (column['property'] === 'missedPlan') {
                 submitState = 3;
                } else if (column['property'] === 'timeOutPlan') {
                 submitState = 4;
                }

                let params = {
                    unionId: this.currentUserItem['unionId'],
                    experimentalId: this.currentItem['id'],
                    projectId: row['projectId'],
                    submitState: submitState
                }

                getDetailList(params).then( res => {
                    if (res.data.code === 0) {
                        if (res.data.data.length > 0) {
                            this.showFillDetailsVisible = true;
                            this.showFillDetailsData = res.data.data;
                        } else {
                            this.$message.error('记录为空');
                        }
                    } else {
                        this.$message.error(res.data.msg);
                        this.showFillDetailsVisible = false;
                    }
                });
            },

            // 填报记录点击
            handleFillProjectRecord(row) {
                console.log(row);



                getProjectDetail(row.id).then( res => {
                    if (res.data.code === 0) {
                        if (res.data.data) {
                            this.showFillProjectDetailsVisible = true;
                            this.showFillProjectDetailsData = res.data.data;
                        } else {
                            this.$message.error('记录为空');
                        }
                    } else {
                        this.$message.error(res.data.msg);
                        this.showFillProjectDetailsVisible = false;
                    }
                });
            },

            //添加子级
            addChild (index) {
                console.log(this.form.experimentalStageDOList);
                for (let item of this.form.experimentalStageDOList) {
                    console.log(item);
                    if (!item['stageName']) {
                        this.$message.error('请输入节点名称');
                        return;
                    }
                    if (!item['startDay'] && item['startDay'] != '0') {
                        this.$message.error('请选择节点起始天数');
                        return;
                    }
                    if (!item['endDay'] && item['startDay'] != '0') {
                        this.$message.error('请选择节点结束天数');
                        return;
                    }
                }

                let maxNode = 0;
                this.form.experimentalStageDOList.forEach((v) => {
                    if (v.stageOrder > maxNode) {
                        maxNode = v.stageOrder;
                    }
                })
                this.form.experimentalStageDOList.splice(index + 1, 0, {stageName: '', stageOrder: maxNode + 1, state: 0});
                console.log(this.form.experimentalStageDOList);
                //this.$set(this.form.node, index + 1, {nodeName: ''});
            },

            // 删除子级
            removeChild(index) {
                console.log(this.form.experimentalStageDOList[index]);
                this.$set(this.form.experimentalStageDOList[index], 'state', 1);
                //  this.$delete(this.form.experimentalStageDOList, index);


            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'page', val);
                this.getData();
            },

            // 分页导航
            handleSelectionPatientChange(val) {
                this.multipleSelection = val;
                console.log(val);
            },
            //
            selectNode(ev, idx) {
                console.log(ev);
                let data = JSON.parse(JSON.stringify(this.form.experimentalStageDOList));

                // data.forEach((value, index, arr) => {
                //     value['stageOrder'] = index;
                // });

                for(let i of data) {
                    for (let value of ev) {
                        if (value === i.stageName) {
                            this.$set(i, 'state', 0);
                            break;
                        } else {
                            this.$set(i, 'state', 1);
                        }

                    }

                }
                console.log(data, this.form.experimentalProjectAOList[idx]);
                this.$set(this.form.experimentalProjectAOList[idx],  'experimentalStageDOList', data );

            },

            // 选择周期类型
            selectProjectType(item) {
                console.log(item, this.form);
                if (item.projectType === '1') {
                    this.$set(item, 'cycle', 0);
                } else {
                    this.$set(item, 'cycle', '');
                }

            },




            getProjectList () {
                let params = {
                    limit: 10,
                    page: 1
                }

            },

            getProjectExperimentalList (id) {
                let params = {
                    experimentalId: id,
                    limit: 10,
                    page: 1
                }
                getList(params).then(res => {
                    //  this.projectList = res.data.data;
                    if (res && res.data.code === 0) {
                        this.patientData = res.data.data;
                        console.log(this.patientData)
                    } else {
                        this.$message.error('查询用户列表失败');
                    }
                });
            },
            getByExperimentInfo(id) {

                getByExperimentCode(id).then(res => {
                    if (res.data.code === 0) {
                        this.form = res.data.data;
                        this.$set(this.form, 'experimentalProjectAOList', this.form.experimentalProjectDTOList);
                        this.form.experimentalProjectAOList.forEach((value, index, arr) => {
                            if (value.cycle === 0) {
                                this.$set(value, 'projectType', '1');
                                let nodeList = [];
                                value.experimentalStageDOList.forEach((v, i, a) => {
                                    nodeList.push(v.stageName);
                                })
                                this.$set(value, 'nodeList', nodeList);
                                console.log(value.nodeList);
                            } else {
                                this.$set(value, 'projectType', '2');
                            }
                        });
                        this.$delete(this.form, 'experimentalProjectDTOList');
                    }
                });
            },
            // 根据试验获取可添加用户列表
            getPatientList (code) {
                getListBySubject(this.currentItem).then( res => {
                    console.log(res);
                    if (res.data && res.data.code === 0) {
                        this.patientData = res.data.data;
                        this.getData();
                    } else {
                        this.$message.error('获取用户列表错误');
                    }
                })
            },

            //添加项目
            addProjectChild (index) {

                for (let item of this.form.experimentalProjectAOList) {
                    if (!item['projectId']) {
                        this.$message.error('请输入项目名称');
                        return;
                    }
                    if (item['projectType'] === '1') {


                        if (item['nodeList'].length === 0) {
                            this.$message.error('请选择节点');
                            return;
                        }

                    } else {
                        if (!item['startDay'] && item['startDay'] != '0') {
                            this.$message.error('请选择项目起始天数');
                            return;
                        }
                        if (!item['endDay'] && item['endDay'] != '0') {
                            this.$message.error('请选择项目结束天数');
                            return;
                        }
                    }
                }
                // let maxNode = 0;
                // this.form.experimentalStageDOList.forEach((v) => {
                //     if (v.stageOrder > maxNode) {
                //         maxNode = v.stageOrder;
                //     }
                // })
                // this.form.experimentalStageDOList.splice(index + 1, 0, {stageName: '', stageOrder: maxNode + 1, state: 0});

                this.form.experimentalProjectAOList.splice(index + 1, 0,  {
                    projectName: '',
                    projectType: '',
                    startTime: '',
                    endTime: '',
                    state: 0,
                    nodeList: []
                });

                //this.$set(this.form.node, index + 1, {nodeName: ''});
            },

            // 删除项目
            removeProjectChild(index) {
                console.log(this.form.experimentalProjectAOList[index]);
                //   this.$delete(this.form.experimentalProjectAOList, index);
                this.$set(this.form.experimentalProjectAOList[index], 'state', 1);


            },
            handleAdd() {
                this.type = 2;
                this.isShow = false;
                this.form = this.initFORM();
                this.editVisible = true;
            },
            select(item, path) {
                this.medicalIndex = item;
                console.log(item);
            },
            show (row) {
                this.currentItem = row;
                this.form = row;
                this.type = 1;

                this.editVisible = true;
                this.isShow = true;
                this.getByExperimentInfo(row.experimentCode);

            },
            edit (row) {
                this.currentItem = row;
                this.form = row;
                this.type = 1;
                this.isShow = false;
                this.editVisible = true;
                this.getByExperimentInfo(row.experimentCode);

            },

            addPatient (row) {
                this.currentItem = row;
                this.form = row;
                this.type = 1;
                this.multipleSelection = [];
                this.addPatientVisible = true;
                this.getPatientList(row.experimentCode);

            },

            showPatient (row) {
                this.currentItem = row;
                this.showPatientData = row.userInfromationDOList;
                this.showPatientVisible = true;

            },

            exportExcel(row, index) {
                console.log(this.currentItem);
                let params = {
                    experimentCode: this.currentItem.id,
                    unionId: row.unionId,
                    fileName: this.currentItem.experimentName.replace('/', '') + '_填报情况'
                }
                console.log(this.currentItem);
                    fillInPlanDownload(params);


            },

            showFill (row, index) {
                console.log(row, this.currentItem);
                this.currentUserItem = row;
                let params = {
                    experimentalId: this.currentItem.id,
                    unionId: row.unionId
                }
                getFillPlan(params).then( res => {
                    if (res.data.code === 0) {
                        this.showFillData = res.data.data;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.showFillVisible = true;
                });


            },
            // 试验关联用户
            addPatientData() {

                if (this.multipleSelection.length === 0) {
                    this.$message.error('请添加患者');
                    return;
                }

                this.currentItem.userInfromationDOList = this.multipleSelection;
                this.currentItem.enrollmentTime = util.formatDate.format(new Date());
                registerSingleSubject(this.currentItem).then(res => {
                    if (res.data.code === 0) {
                        this.$message.success('关联用户成功');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.addPatientVisible = false;
                });
            },
            editFsq (row) {
                this.formFsq = row;
                this.editFsqVisible = true;

            },

            // 获取 easy-mock 的模拟数据
            getData() {
                getSubjectList(this.query).then( res => {
                    console.log(res);
                    if (res && res.data.code === 0) {
                        this.tableData = res.data.data.content;
                        this.total = res.data.data.total;

                    } else {
                        this.$message.error('查询失败');
                    }
                });
            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'page', 1);
                this.getData();
            },
            // 删除操作
            handleDelete(row, index) {
                // 二次确认删除
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                })
                    .then(() => {
                        let params = {
                            experimentCode: row.experimentCode
                        }
                        experimentalDelete(params).then( res => {
                            if (res.data.code === 0) {
                                this.$message.success('删除成功');
                                this.getData();
                            } else {
                                this.$message.success('删除失败');
                            }
                        });
                    })
                    .catch(() => {});
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log(val);
            },
            delAllSelection() {
                const length = this.multipleSelection.length;
                let str = '';
                this.delList = this.delList.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].name + ' ';
                }
                this.$message.error(`删除了${str}`);
                this.multipleSelection = [];
            },
            // 编辑操作
            handleEdit(index, row) {
                this.idx = index;
                this.form = row;
                this.editVisible = true;
                console.log(row);
                this.getProjectExperimentalList(row.id);
            },
            // 保存编辑
            saveEdit() {
                this.editVisible = false;
                this.$set(this.tableData, this.idx, this.form);
            },
            // 添加
            addEdit() {

                if (this.currentItem.experimentState === 1 && !this.validateForm()) {
                    return;
                }
                // this.form.experimentalStageDOList.forEach((value, index, arr) => {
                //     value['stageOrder'] = index;
                // });
                console.log(this.form.experimentalStageDOList)
                //  this.editVisible = false;
                insertData(this.form).then( res => {
                    if (res.data && res.data.code === 0) {
                        this.$message.success('保存试验成功');
                        this.editVisible = false;
                        this.getData();
                    } else {
                        this.$message.error('添加组会失败');
                    }
                }, err => {
                    this.$message.error('添加组会失败');
                });

            },



            // 验证form
            validateForm () {

                if (!this.form.experimentName) {
                    this.$message.error('请输入组会名称');
                    return false;
                }
                if (!this.form.unitName) {
                    this.$message.error('请输入单位名称');
                    return false;
                }
                if (!this.form.unitCode) {
                    this.$message.error('请输入单位编码');
                    return false;
                }
                if (!this.form.startTime) {
                    this.$message.error('请选择试验开始时间');
                    return false;
                }
                if (!this.form.endTime) {
                    this.$message.error('请选择试验结束时间');
                    return false;
                }

                for (let item of this.form.experimentalStageDOList) {
                    console.log(item);
                    if (!item['stageName']) {
                        this.$message.error('请输入节点名称');
                        return false;
                    }
                    if (!item['startTime']) {
                        this.$message.error('请选择节点起始时间');
                        return false;
                    }
                    if (!item['endTime']) {
                        this.$message.error('请选择节点结束时间');
                        return false;
                    }
                }

                for (let item of this.form.experimentalProjectAOList) {
                    console.log(item);
                    if (!item['projectId']) {
                        this.$message.error('请输入项目名称');
                        return false;
                    }
                    if (item['projectType'] === '1') {


                        if (item['nodeList'].length === 0) {
                            this.$message.error('请选择节点');
                            return false;
                        }

                    } else {
                        if (!item['visitStartTime']) {
                            this.$message.error('请选择项目起始时间');
                            return false;
                        }
                        if (!item['visitEndTime']) {
                            this.$message.error('请选择项目结束时间');
                            return false;
                        }
                    }
                }




                return true;
            },


            initFORM () {
                return {
                    experimentalStageDOList: [
                        {
                            stageName: '',
                            visitStartTime: '',
                            visitEndTime: '',
                            state: 0,
                            stageOrder: 0,
                        }
                    ],
                    experimentalProjectAOList: [
                        {
                            projectName: '',
                            projectType: '',
                            cycle: '',
                            visitStartTime: '',
                            visitEndTime: '',
                            state: 0,
                            experimentalStageDOList: []
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .table {
        width: 100%;
        font-size: 14px;
    }
    .red {
        color: #ff0000;
    }
    .mr10 {
        margin-right: 10px;
    }
    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .shiyan /deep/ .el-input__inner:focus {
        background: #409EFF;
        color: #ffffff;
        border-top-color: #409EFF;
    }

    .shiyan /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }

    .row-bottom {
        border-bottom: 1px solid #eee;
        padding-top: 10px;
    }

    .selectNode /deep/ .el-select.el-select--mini {
        width: 300px;
    }

    .fill-table /deep/ .el-table .cell{
        color: dodgerblue;
        text-align: center;
        cursor: pointer;
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>