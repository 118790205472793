import { req } from '../api.http'

// 获取计划列表
export const getPlanList = (params = {}) => {
  return req('post', '/web/planManagement/getPlanPageList', params)

}
// 获取计划列表不分页
export const getPlanList2 = (params = {}) => {
  return req('post', '/web/planManagement/getPlanList', params)

}


// 创建组会或面谈计划
export const creatWeeklyMeeting = (params = {}) => {
  return req('post', '/web/planManagement/creatAutomaticPlan', params)

}
// 导入组会学生
export const createStudentPlan = (params = {}) => {
  return req('post', '/web/groupWill/createStudentPlan', params)

}
//  生成学生面谈计划
export const interviewcreateStudentPlan = (params = {}) => {
  return req('post', '/web/interview/createStudentPlan', params)

}

// 获取组会详情
export const getStudentByPlanId = (params = {}) => {
  return req('post', '/web/groupWill/getStudentByPlanId', params)

}

// 修改组会计划
export const groupWillUpdateData = (params = {}) => {
  return req('post', '/web/groupWill/groupWill/updateData', params)

}

// 获取学生列表
export const tutorGetStudentList = (params = {}) => {
  return req('post', '/web/studentStatus/getStudentList', params)

}

// 获取学期列表
export const getSchoolYearList = (params = {}) => {
  return req('post', '/web/planManagement/getSchoolYearList', params)

}

// 导师查看各状态组会列表
export const getWillListByTutor = (params = {}) => {
  return req('post', '/web/groupWill/getWillListByTutor', params)

}

// 学员查看自己的组会计划
export const getWillListByStudent = (params = {}) => {
  return req('post', '/web/groupWill/getWillListByStudent', params)

}
// 导师修改学生组会信息
export const updateGroupWill = (params = {}) => {
  return req('post', '/web/groupWill/updateGroupWill', params)

}
// 获取匿名信列表
export const getAnonymousLetterList = (params = {}) => {
  return req('post', '/web/mailbox/getAnonymousLetterList', params)

}
// 批量更新组会
export const batchUpdateTime = (params = {}) => {
  return req('post', '/web/groupWill/batchUpdateTime', params)

}

// 导师查看组会报告
export const getById = (id = {}) => {
  return req('get', `/web/groupWill/getById/${id}`)

}
// 查询单条学术报告
export const academicReportGetById = (id = {}) => {
  return req('get', `/web/academicReport/getById/${id}`)

}

// 删除单条学术报告
export const academicReportDeleteById = (id = {}) => {
  return req('get', `/web/academicReport/deleteById/${id}`)

}


// 获取学术报告列表
export const academicReportGetList = (params = {}) => {
  return req('post', '/web/academicReport/getAcademicReportPageList', params)

}





// 导入面谈计划
export const interviewCreateTutorPlan = (params = {}) => {
  return req('post', '/web/planManagement/creatAutomaticPlan', params)

}


// 面谈报告列表
export const interviewGetPageList = (params = {}) => {
  return req('post', '/web/interview/getPageList', params)

}
// 发布问卷
export const published = (params = {}) => {
  return req('post', '/web/publish/published', params)

}

// 发布列表
export const publishGetList = (params = {}) => {
  return req('post', '/web/publish/getPublishList', params)

}

// 问卷列表
export const questionGetPageList = (params = {}) => {
  return req('post', '/web/questionnaireInformation/getPageList', params)

}

// 问卷头创建
export const questionInsertQuestionnaire = (params = {}) => {
  return req('post', '/web/questionnaireInformation/insertQuestionnaire', params)

}

// 问卷头修改
export const questionnaireInformationUpdateData = (params = {}) => {
  return req('post', '/web/questionnaireInformation/updateData', params)

}

// 计划一级列表
export const getPlanFirst = (params = {}) => {
  return req('post', '/web/plan/getPlanFirst', params)

}
// 问题创建
export const questionInsertData = (params = {}) => {
  return req('post', '/web/question/insertData', params)

}
// 问题修改
export const questionupdateData = (params = {}) => {
  return req('post', '/web/question/updateData', params)

}
// 查询问题
export const questionGetList = (params = {}) => {
  return req('post', '/web/question/getList', params)

}

// 查询问题
export const questionGetById = (id) => {
  return req('get', `/web/question/getById/${id}`)

}
// 删除问题
export const questionDeleteById = (id) => {
  return req('get', `/web/question/deleteById/${id}`)

}

// 根据计划id 获取所有计划
export const planGetById = (id) => {
  return req('get', `/web/plan/getById/${id}`)

}
// 查看面谈计划
export const getInterviewPageList = (params = {}) => {
  return req('post', '/web/interview/getInterviewPageList', params)

}







