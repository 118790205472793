<template>
    <div class="shiyan">
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.title" placeholder="计划名称" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                <template v-if="username === 'admin'">
                    <el-button type="primary" style="float: right" @click="handleAdd">创建填报计划</el-button>
                </template>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <!--                <el-table-column type="selection" width="55" align="center"></el-table-column>-->
                <!--                <el-table-column prop="experiment_code" label="试验编号"></el-table-column>-->
                <el-table-column prop="studentName" label="学生姓名"></el-table-column>
                <el-table-column prop="tutorNumber" label="导师工号"></el-table-column>
                <el-table-column prop="academicYear" label="计划学年"></el-table-column>
                <el-table-column prop="academicYear" label="问卷名称">
                    <template slot-scope="scope">
                        {{ scope.row.questionnaireInformationDO.questionnaireName }}
                    </template>
                </el-table-column>
                <el-table-column prop="strDate" label="开始时间">
                    <template slot-scope="scope">
                        {{ scope.row.startTime && scope.row.startTime.split(' ')[0] }}
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="结束时间">
                    <template slot-scope="scope">
                        {{ scope.row.endTime && scope.row.endTime.split(' ')[0] }}
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="填写状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status === 1" style="color: orangered">已填写</span>
                        <span v-if="scope.row.status === 0">未填写</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="endTime" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini">查看</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="total"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>

            <!--            <el-upload-->
            <!--                    class="upload-demo"-->
            <!--                    action="http://192.168.0.173:40071/web/experimentalSubject/upload/"-->
            <!--                    :on-preview="handlePreview"-->
            <!--                    :on-remove="handleRemove"-->
            <!--                    :before-remove="beforeRemove"-->
            <!--                    multiple-->
            <!--                    :limit="3"-->
            <!--                    :on-exceed="handleExceed"-->
            <!--                    :file-list="fileList">-->
            <!--                <el-button size="small" type="primary">点击上传</el-button>-->
            <!--            </el-upload>-->
        </div>

        <!-- 添加组会 -->
        <el-dialog title="创建填报计划" :visible.sync="editVisible" width="500px">
            <el-form ref="form" :model="form">
                <el-row>
                    <el-col>
                        <el-form-item label="开始时间" label-width="110px">
                            <el-date-picker
                                v-model="form.strDate"
                                :disabled="isShow"
                                value-format="yyyy-MM-dd"
                                size="mini"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="结束时间" label-width="110px">
                            <el-date-picker
                                v-model="form.endDate"
                                :disabled="isShow"
                                value-format="yyyy-MM-dd"
                                size="mini"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="计划学年" label-width="110px">
                            <el-select v-model="form.schoolYear" :disabled="isShow">
                                <el-option :label="nowSchoolYear + '年上学期'" :value="nowSchoolYear + '年上学期'"></el-option>
                                <el-option :label="nowSchoolYear + '年下学期'" :value="nowSchoolYear + '年下学期'"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="问卷列表" label-width="110px">
                            <el-select v-model="form.surveyId" :disabled="isShow">
                                <el-option
                                    v-for="(item, index) in questionList"
                                    :label="item.questionnaireName"
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!--                <el-row>-->
                <!--                    <el-col  >-->
                <!--                        <el-form-item label="计划列表" label-width="110px" >-->
                <!--                            <el-select v-model="form.planId"  >-->
                <!--                                <el-option v-for="(item, index) in planList" :label="item.schoolYear + '第' + item.frequency + '次' + item.title" :value="item.id" ></el-option>-->
                <!--                            </el-select>-->
                <!--                        </el-form-item>-->
                <!--                    </el-col>-->
                <!--                </el-row>-->

                <!--                <el-form-item label="组会状态">-->
                <!--                    <el-input v-model="form.experiment_state"></el-input>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="数据状态">-->
                <!--                    <el-input v-model="form.state"></el-input>-->
                <!--                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="addEdit" v-if="!isShow">保 存</el-button>
            </span>
        </el-dialog>

        <!-- 编辑访视期 -->
        <el-dialog title="添加项目" :visible.sync="editFsqVisible" width="500px">
            <el-form ref="form" :model="formFsq" label-width="110px">
                <el-form-item label="访视1">
                    <el-select v-model="fsValue" multiple placeholder="请选择">
                        <el-option v-for="(item, index) in projectList" :key="index" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <!--                <el-form-item label="访视2">-->
                <!--                    <el-input v-model="formFsq.fs2" style="width: 200px;" ></el-input>-->
                <!--                    ±-->
                <!--                    <el-input-number :min="0" v-model="formFsq.fs2error"></el-input-number>-->
                <!--                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editFsqVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 导入面谈计划-->
        <el-dialog title="导入面谈计划" :visible.sync="planVisible" width="500px">
            <div style="padding: 10px">
                学年：
                <el-select v-model="importSchoolYear" size="mini">
                    <el-option :label="item" :value="item" v-for="(item, index) in schoolYearList" :key="index"></el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="planVisible = false">取 消</el-button>
                <el-button type="primary" @click="addPlan">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 导入学生-->
        <el-dialog title="参加组会学生列表" :visible.sync="stuPlanVisible" width="1000px">
            <div style="overflow: auto">
                <el-table
                    ref="multipleTable"
                    @selection-change="handleStudentChange"
                    style="max-height: 500px; overflow: auto"
                    :data="studentPlanList"
                    border
                    class="table"
                >
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="username" label="学号"></el-table-column>
                    <el-table-column prop="nickname" label="姓名"></el-table-column>
                    <el-table-column prop="telephone" label="号码"></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="stuPlanVisible = false">取 消</el-button>
                <el-button type="primary" @click="stuPlanVisible = false">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 试验患者填报记录 -->
        <el-dialog title="填报记录" :visible.sync="showFillVisible" width="700px">
            <div style="overflow: auto">
                <el-table style="max-height: 500px; overflow: auto" :data="showFillData" border class="table">
                    <el-table-column prop="projectName" label="项目名称"></el-table-column>
                    <el-table-column prop="unFillPlan" label="未填报">
                        <template slot-scope="scope">
                            {{ scope.row.unFillPlan ? scope.row.unFillPlan : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="filledPlan" label="已填报">
                        <template slot-scope="scope">
                            {{ scope.row.filledPlan ? scope.row.filledPlan : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="missedPlan" label="漏填">
                        <template slot-scope="scope">
                            {{ scope.row.missedPlan ? scope.row.missedPlan : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeOutPlan" label="超时未填">
                        <template slot-scope="scope">
                            {{ scope.row.timeOutPlan ? scope.row.timeOutPlan : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalPlan" label="合计"></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showFillVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    getPlanList,
    getPlanList2,
    creatWeeklyMeeting,
    createStudentPlan,
    interviewCreateTutorPlan,
    tutorGetStudentList,
    getSchoolYearList,
    getStudentByPlanId,
    published,
    publishGetList,
    questionGetPageList,
    groupWillUpdateData
} from '@/apis/project/index';
import util from '@/utils/util';
export default {
    name: 'medical',
    components: {},
    computed: {
        projectToNode() {
            console.log(this.form);
            return this.form.experimentalStageDOList.filter((item) => {
                return item.stageName.trim() !== '' && item.startDay >= 0 && item.endDay >= 0;
            });
        }
    },
    data() {
        return {
            nowSchoolYear: new Date().getFullYear(),
            stuPlanVisible: false,
            studentPlanList: [],
            username: sessionStorage.getItem('username'),
            stuForm: {
                limit: 1000,
                page: 1
            },
            schoolYearList: [],
            selectStudentList: [],
            studentList: [],
            importSchoolYear: '',
            fileList: [
                {
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                },
                {
                    name: 'food2.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }
            ],
            nodeName: '',
            projectList: [],
            fsValue: '',
            query: {
                title: '',
                planType: '',
                schoolYear: '',
                strDate: '',
                endDate: '',
                state: 0,
                content: ''
            },
            medicalIndex: undefined,
            total: 0,
            tableData: [],
            planList: [],
            questionList: [],
            patientData: [],
            showPatientData: [],
            showFillData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            stuVisible: false,
            planVisible: false,
            addPatientVisible: false,
            showPatientVisible: false,
            editFsqVisible: false,
            form: this.initFORM(),
            formFsq: {},
            id: -1,
            items: [],
            currentItem: [],
            visible: false,
            isShow: false,
            showFillVisible: false
        };
    },

    mounted() {
        this.getData();
        this.getQuestionList();
    },
    methods: {
        getPlanList() {
            getPlanList2(this.query).then((res) => {
                if (res && res.data.code === 0) {
                    this.planList = res.data.data;
                } else {
                    this.$message.error('查询失败');
                }
            });
        },

        getSchoolYear() {
            getSchoolYearList().then((res) => {
                this.schoolYearList = res.data.data;
            });
        },
        getPlanDetail(row, index) {
            let params = {
                planId: row.id,
                tutorCode: sessionStorage.getItem('username') === 'admin' ? null : sessionStorage.getItem('username')
            };

            getStudentByPlanId(params).then((res) => {
                this.stuPlanVisible = true;
                this.studentPlanList = res.data.data;
            });
        },
        /**
         * 获取问卷列表
         */
        getQuestionList() {
            questionGetPageList({ limit: 1000, page: 1, questionnaireType: '1' }).then((res) => {
                if (res.data.code === 0) {
                    this.questionList = res.data.data.content.filter((item) => {
                        return item.status != 0;
                    });
                } else {
                    this.$message.error('获取问卷列表失败');
                }
            });
        },

        // 获取学生列表
        getStudentList() {
            tutorGetStudentList({}).then((res) => {
                if (res.data.code === 0) {
                    console.log(res.data.data);
                    this.studentList = res.data.data;
                    this.getSchoolYear();
                }
            });
        },
        // 选择学生
        handleStudentChange(ev) {
            this.selectStudentList = ev;
        },
        // 导入学生
        addStudent() {
            createStudentPlan({
                adminDOList: this.selectStudentList,
                schoolYear: this.importSchoolYear,
                username: sessionStorage.getItem('username')
            }).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('导入学生成功');
                    this.stuVisible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },

        // 导入面谈计划
        addPlan() {
            interviewCreateTutorPlan({ schoolYear: this.importSchoolYear }).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('导入面谈计划成功');
                    this.planVisible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },

        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'page', val);
            this.getData();
        },

        // 分页导航
        handleSelectionPatientChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        //

        // 选择周期类型
        selectProjectType(item) {
            console.log(item, this.form);
            if (item.projectType === '1') {
                this.$set(item, 'cycle', 0);
            } else {
                this.$set(item, 'cycle', '');
            }
        },

        getProjectList() {
            let params = {
                limit: 10,
                page: 1
            };
        },

        getProjectExperimentalList(id) {
            let params = {
                experimentalId: id,
                limit: 10,
                page: 1
            };
            getList(params).then((res) => {
                //  this.projectList = res.data.data;
                if (res && res.data.code === 0) {
                    this.patientData = res.data.data;
                    console.log(this.patientData);
                } else {
                    this.$message.error('查询用户列表失败');
                }
            });
        },

        //添加项目
        addProjectChild(index) {
            for (let item of this.form.experimentalProjectAOList) {
                if (!item['projectId']) {
                    this.$message.error('请输入项目名称');
                    return;
                }
                if (item['projectType'] === '1') {
                    if (item['nodeList'].length === 0) {
                        this.$message.error('请选择节点');
                        return;
                    }
                } else {
                    if (!item['startDay'] && item['startDay'] != '0') {
                        this.$message.error('请选择项目起始天数');
                        return;
                    }
                    if (!item['endDay'] && item['endDay'] != '0') {
                        this.$message.error('请选择项目结束天数');
                        return;
                    }
                }
            }
            // let maxNode = 0;
            // this.form.experimentalStageDOList.forEach((v) => {
            //     if (v.stageOrder > maxNode) {
            //         maxNode = v.stageOrder;
            //     }
            // })
            // this.form.experimentalStageDOList.splice(index + 1, 0, {stageName: '', stageOrder: maxNode + 1, state: 0});

            this.form.experimentalProjectAOList.splice(index + 1, 0, {
                projectName: '',
                projectType: '',
                startTime: '',
                endTime: '',
                state: 0,
                nodeList: []
            });

            //this.$set(this.form.node, index + 1, {nodeName: ''});
        },

        // 删除项目
        removeProjectChild(index) {
            console.log(this.form.experimentalProjectAOList[index]);
            //   this.$delete(this.form.experimentalProjectAOList, index);
            this.$set(this.form.experimentalProjectAOList[index], 'state', 1);
        },
        handleAdd() {
            this.type = 2;
            this.isShow = false;
            this.getPlanList();
            this.getSchoolYear();
            this.form = this.initFORM();
            this.editVisible = true;
            console.log(this.form);
        },

        handleStu() {
            this.stuVisible = true;
            console.log(this.form);
        },
        handlePlan() {
            this.planVisible = true;
            console.log(this.form);
        },
        select(item, path) {
            this.medicalIndex = item;
            console.log(item);
        },
        show(row) {
            this.currentItem = row;
            this.currentItem.planType = this.currentItem.planType.toString();
            this.form = row;

            //  this.editVisible = true;
            // this.isShow = true;
            // this.getByExperimentInfo(row.experimentCode);
        },
        edit(row) {
            this.currentItem = row;
            this.form = row;
            this.type = 1;
            this.isShow = false;
            this.editVisible = true;
            //  this.getByExperimentInfo(row.experimentCode);
        },

        addPatient(row) {
            this.currentItem = row;
            this.form = row;
            this.type = 1;
            this.multipleSelection = [];
            this.addPatientVisible = true;
        },

        showPatient(row) {
            this.currentItem = row;
            this.showPatientData = row.userInfromationDOList;
            this.showPatientVisible = true;
        },
        showFill(row, index) {
            console.log(row, this.currentItem);
            let params = {
                experimentalId: this.currentItem.id,
                unionId: row.unionId
            };
            getFillPlan(params).then((res) => {
                if (res.data.code === 0) {
                    this.showFillData = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
                this.showFillVisible = true;
            });
        },
        // 试验关联用户
        addPatientData() {
            this.currentItem.userInfromationDOList = this.multipleSelection;
            this.currentItem.enrollmentTime = util.formatDate.format(new Date());
            registerSingleSubject(this.currentItem).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('关联用户成功');
                } else {
                    this.$message.error(res.data.msg);
                }
                this.addPatientVisible = false;
            });
        },
        editFsq(row) {
            this.formFsq = row;
            this.editFsqVisible = true;
        },

        // 获取 easy-mock 的模拟数据
        getData() {
            publishGetList(this.query).then((res) => {
                if (res && res.data.code === 0) {
                    this.tableData = res.data.data;
                } else {
                    this.$message.error('查询失败');
                }
            });
        },
        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, 'page', 1);
            this.getData();
        },
        // 删除操作
        handleDelete(row, index) {
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    let params = {
                        experimentCode: row.experimentCode
                    };
                    experimentalDelete(params).then((res) => {
                        if (res.data.code === 0) {
                            this.$message.success('删除成功');
                            this.getData();
                        } else {
                            this.$message.success('删除失败');
                        }
                    });
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = '';
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + ' ';
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
            console.log(row);
            this.getProjectExperimentalList(row.id);
        },
        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            this.$set(this.tableData, this.idx, this.form);
        },

        // 导出excel
        exportExcel() {
            console.log(this.currentItem.experimentName.replace('/', ''));
            experimentalSubjectDownload(this.currentItem.experimentCode, this.currentItem.experimentName.replace('/', ''));
            // experimentalSubjectDownload(this.currentItem.experimentCode).then(res => {
            //     if (res.data) {
            //         var a = document.createElement('a');
            //         var url = window.URL.createObjectURL(new Blob([res.data],
            //             { type: ( "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")}));
            //         a.href = url;
            //         a.download = this.currentItem.experimentName || 'file';
            //         a.click();
            //         window.URL.revokeObjectURL(url);
            //
            //     }
            // })
        },

        // 添加
        addEdit() {
            console.log(this.form);
            if (!this.form.strDate) {
                this.$message.error('请选择开始时间');
                return;
            }
            if (!this.form.endDate) {
                this.$message.error('请选择结束时间');
                return;
            }
            if (!this.form.schoolYear) {
                this.$message.error('请选择学年学期');
                return;
            }
            if (!this.form.surveyId) {
                this.$message.error('调查问卷');
                return;
            }

            // this.form.experimentalStageDOList.forEach((value, index, arr) => {
            //     value['stageOrder'] = index;
            // });
            //  this.editVisible = false;
            published(this.form).then(
                (res) => {
                    if (res.data && res.data.code === 0) {
                        this.$message.success('创建计划成功');
                        this.editVisible = false;
                        this.getData();
                    } else {
                        this.$message.error('创建计划失败');
                    }
                },
                (err) => {
                    this.$message.error('创建计划失败');
                }
            );
        },

        // 验证form
        validateForm() {
            if (!this.form.experimentName) {
                this.$message.error('请输入组会名称');
                return false;
            }
            if (!this.form.unitName) {
                this.$message.error('请输入单位名称');
                return false;
            }
            if (!this.form.unitCode) {
                this.$message.error('请输入单位编码');
                return false;
            }
            if (!this.form.startTime) {
                this.$message.error('请选择试验开始时间');
                return false;
            }
            if (!this.form.endTime) {
                this.$message.error('请选择试验结束时间');
                return false;
            }

            for (let item of this.form.experimentalStageDOList) {
                console.log(item);
                if (!item['stageName']) {
                    this.$message.error('请输入节点名称');
                    return false;
                }
                if (!item['startTime']) {
                    this.$message.error('请选择节点起始时间');
                    return false;
                }
                if (!item['endTime']) {
                    this.$message.error('请选择节点结束时间');
                    return false;
                }
            }

            for (let item of this.form.experimentalProjectAOList) {
                console.log(item);
                if (!item['projectId']) {
                    this.$message.error('请输入项目名称');
                    return false;
                }
                if (item['projectType'] === '1') {
                    if (item['nodeList'].length === 0) {
                        this.$message.error('请选择节点');
                        return false;
                    }
                } else {
                    if (!item['visitStartTime']) {
                        this.$message.error('请选择项目起始时间');
                        return false;
                    }
                    if (!item['visitEndTime']) {
                        this.$message.error('请选择项目结束时间');
                        return false;
                    }
                }
            }

            return true;
        },

        initFORM() {
            return {
                planId: 4,
                schoolYear: '',
                strDate: '',
                endDate: '',
                surveyId: ''
            };
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.shiyan /deep/ .el-input__inner:focus {
    background: #409eff;
    color: #ffffff;
    border-top-color: #409eff;
}

.shiyan /deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.row-bottom {
    border-bottom: 1px solid #eee;
    padding-top: 10px;
}

.selectNode /deep/ .el-select.el-select--mini {
    width: 300px;
}
</style>
