<template>
    <div>
        <div class="crumbs">
        </div>
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.nickName" placeholder="用户名" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
<!--                <el-button type="primary" style="float: right;"  @click="handleAdd">添加患者</el-button>-->
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column prop="account" label="学号"></el-table-column>
                <el-table-column prop="realName" label="姓名"></el-table-column>
<!--                <el-table-column prop="age" label="年龄"></el-table-column>-->
                <el-table-column prop="college" label="学院" width="125"></el-table-column>
                <el-table-column prop="education" label="学历" width="125">
                    <template slot-scope="scope">
                        <span v-if="scope.row.education == 1">博士</span>
                        <span v-if="scope.row.education == 2">硕士</span>
                        <span v-if="scope.row.education == 3">本科</span>
                        <span v-if="scope.row.education == 4">其他</span>
                    </template>
                </el-table-column>
                <el-table-column prop="studentStatus" label="学籍状态" width="125">
                    <template slot-scope="scope">
                        <span v-if="scope.row.studentStatus == 1">在读</span>
                        <span v-if="scope.row.studentStatus == 2">毕业</span>
                    </template>
                </el-table-column>
                <el-table-column prop="tutorName" label="导师姓名"></el-table-column>
                <el-table-column prop="jobNumber" label="导师工号"></el-table-column>
                <el-table-column label="专业" prop="profession"></el-table-column>
                <el-table-column label="专业代码" prop="professionCode"></el-table-column>

                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                            <el-button
                                    :disabled="!(scope.row.isDischarge !== 1)"
                                    type="text"
                                    icon="el-icon-edit"
                                    @click="handleCheck(scope.$index, scope.row)"

                            >审核</el-button>
                        <el-button
                                :disabled="!(scope.row.isDischarge !== 1)"
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.$index, scope.row)"

                        >编辑</el-button>

                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="handleDelete(scope.$index, scope.row)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="padding-top: 10px;">
<!--                <el-button type="primary" style="float: left; "  @click="handleGroupAdd">批量入组</el-button>-->
            </div>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.page"
                        :page-size="query.limit"
                        :total="total"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog :title="visibleType === 0 ? '编辑' : '保存'" :visible.sync="editVisible" width="390px">
            <el-form ref="form"  :model="form" label-width="100px">
                <el-form-item label="昵称">
                    <el-input v-model="form.nickName" ></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="form.phone"  ></el-input>
                </el-form-item>
<!--                <el-form-item label="身份证号">-->
<!--                    <el-input v-model="form.idCard"  ></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="性别">
                    <el-select v-model="form.sex">
                        <el-option value="男" label="男"></el-option>
                        <el-option value="女" label="女"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出生日期">
                    <el-date-picker v-model="form.birthday" value-format="yyyy-MM-dd" ></el-date-picker>
                </el-form-item>


            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="审核" :visible.sync="checkVisible" width="30%">
            <el-form ref="form"  :model="form" label-width="90px">

                <el-form-item >
                    <el-radio-group v-model="form.identityState">
                        <el-radio :label="0">游客</el-radio>
                        <el-radio :label="3">审核驳回</el-radio>
                        <el-radio :label="2">审核通过</el-radio>
                    </el-radio-group>

                </el-form-item >

                <span v-if="  form.identityState === 2">
                    <el-form-item label="是否出院">
                        <el-select v-model="form.isDischarge" @change="form.dischargeDate = ''">
                            <el-option :value="0" label="未出院"></el-option>
                            <el-option :value="1" label="已出院"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出院日期" v-if="form.isDischarge === 1">
                        <el-date-picker v-model="form.dischargeDate" value-format="yyyy-MM-dd" ></el-date-picker>
                    </el-form-item>
                </span>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeCheck">取 消</el-button>
                <el-button type="primary" @click="changeCheck(form)">确 定</el-button>
            </span>

        </el-dialog>

        <el-dialog title="批量入组" :visible.sync="groupVisible" width="30%">
            <el-form ref="form"  :model="form" label-width="90px">
                <el-form-item label="试验列表">
                    <el-select v-model="batchExperimental" @change="selectExperimental">
                        <el-option v-for="(item, index) in ExperimentalList" :key="index" :label="item.experimentName" :value="item.experimentCode"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入组时间">
                    <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="inDate"
                            value-format="yyyy-MM-dd"
                            style="width: 100%;"
                    ></el-date-picker>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="groupVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveBatch">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Sortable from 'sortablejs';
    import {tutorGetStudentList} from '@/apis/project/index';
    import {getSubjectList} from '@/apis/Experimental/index';
    export default {
        name: 'dict',
        data() {
            return {
                query: {
                    page: 1,
                    // "identityState": 0,
                    limit: 10
                },
                tableData: [],
                total: 0,
                multipleSelection: [],
                delList: [],
                ExperimentalList: [],
                editVisible: false,
                checkVisible: false,
                groupVisible: false,
                pageTotal: 0,
                visibleType: 0,
                batchExperimental: '',
                inDate: '',
                batchExperimentalId: '',
                form: {
                    categoryName: '',
                    children: [
                        {categoryName: ''}
                    ]
                },
                idx: -1,
                id: -1,
            };
        },
        created() {
             this.getData();
          //   this.getSubjectData();
        },
        computed: {
          formChild: function()  {
             let list = this.form.children.filter(res => {
                 console.log(res);
                 return res.isDel !== 1;
             })
              console.log(list);
              return list;
          }
        },
        mounted () {
        },
        methods: {

            closeCheck(){
                this.getData();
                this.checkVisible = false;
            },

            selectExperimental(item) {
                console.log(item);
                this.ExperimentalList.forEach((value, index) => {
                    console.log(value);
                    if (item === value.experimentCode) {
                        this.batchExperimentalId = value.id;
                    }
                })
            },
            changeCheck(row) {
                console.log(row);
                if (row.identityState === 2) {
                    this.$confirm('确定审核通过吗？', '提示', {
                        type: 'warning'
                    })
                        .then(() => {

                            authoriseUser(row).then(res => {
                                console.log(res);
                                this.checkVisible = false;
                            });
                        })
                        .catch(() => {
                            row.identityState = 3;
                        });
                } else if (row.identityState === 3){
                    this.$confirm('确定驳回审核吗？', '提示', {
                        type: 'warning'
                    })
                        .then(() => {
                            authoriseUser(row).then(res => {
                                console.log(res);
                                this.checkVisible = false;
                            });

                        })
                        .catch(() => {
                            row.identityState = 2;
                        });
                }
            },
            // 获取 easy-mock 的模拟数据
            getData() {

                tutorGetStudentList(this.query).then( res => {
                        if (res && res.data.code === 0) {
                             this.tableData = res.data.data.content;
                             this.total = res.data.data.total;
                        } else {
                            this.$message.error('查询失败');
                        }
                        console.log(this.tableData);
                    });

            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'page', 1);
                this.getData();
            },
            getSubjectData() {
                getSubjectList(this.query).then( res => {
                    console.log(res);
                    if (res && res.data.code === 0) {

                        this.ExperimentalList = res.data.data.content;
                        console.log(this.ExperimentalList);
                    } else {
                        this.$message.error('查询失败');
                    }
                });
            },
            getRowKey (row) {
                return row.id + row.categoryName;
            },
            // 删除操作
            handleDelete(index, row) {
                // 二次确认删除
                console.log(row);
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                })
                    .then(() => {
                        deleteByOpenId(row.unionId).then(res => {
                            if (res.data.code === 0) {
                                this.getData();
                                this.$message.success('删除成功');
                            } else {
                                this.$message.error(res.msg);
                            }
                        })

                    })
                    .catch(() => {});
            },

            //添加子级
            addChild () {
                this.$set(this.form.children, this.form.children.length, {categoryName: ''});
            },

            // 删除子级
            removeChild(index) {
                console.log(this.form.children[index]);
                if (this.form.children[index].id) {
                    this.$set(this.form.children[index], 'isDel', 1);
                } else {
                    this.$delete(this.form.children, index);
                }

            },

            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log(val);
            },
            delAllSelection() {
                const length = this.multipleSelection.length;
                let str = '';
                this.delList = this.delList.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].name + ' ';
                }
                this.$message.error(`删除了${str}`);
                this.multipleSelection = [];
            },

            // 向上
            handleUp( row) {
                console.log(row);
                let index = row.sort;
                if (index === 0) {
                    return;
                }

                let tempOption = this.tableData[index - 1];
                this.$set(this.tableData, index - 1, this.tableData[index]);
                this.$set(this.tableData, index, tempOption);
                this.tableData = this.tableData.map((item, index) => {
                    item.sort = index;
                    return item;
                });
            },
            handleChildUp (row, index) {
                console.log(row);
                if (index === 0) {
                    return;
                }

                let tempOption = this.form.children[index - 1];
                this.$set(this.form.children, index - 1, this.form.children[index]);
                this.$set(this.form.children, index, tempOption);
                // this.form.children = this.form.children.map((item, index) => {
                //     if (item) {
                //         item.sort = index;
                //         return item;
                //     }
                //
                // });

            },

            // 审核操作
            handleCheck(index, row) {
                this.idx = index;
                this.form = row;
                console.log(this.form);
                this.checkVisible = true;

            },
            // 编辑操作
            handleEdit(index, row) {
                this.idx = index;
                this.form = row;
                console.log(this.form);
                this.visibleType = 0;
                this.editVisible = true;
                getUserInfo(row.id).then( res => {
                    console.log(res);
                });
            },

            // 添加
            handleAdd () {
                this.visibleType = 1;
                this.form = {
                    categoryName: '',
                    children: [
                        {categoryName: ''}
                    ]
                };
              this.editVisible = true;
            },

            handleGroupAdd () {

                if (this.multipleSelection.length === 0) {
                    this.$message.error('请选择患者');
                    return;
                }
              this.groupVisible = true;
            },

            // 保存编辑
            saveEdit() {
                this.editVisible = false;
                updateUserInfo(this.form).then( res => {
                    if (res.data.code === 0) {
                        this.$message.success('修改成功');
                        this.getData();
                    } else {
                        this.$message.error('修改失败');
                    }
                });
            },
            // 保存批量入组
            saveBatch() {
                this.groupVisible = false;
                let userInformationDOList = [];

                if (!this.inDate) {
                    this.$message.error('请选择入组时间');
                    return;
                }

                this.multipleSelection.forEach((value) => {
                    userInformationDOList.push(value);
                })
                console.log(userInformationDOList);
                let params = {
                    experimentCode: this.batchExperimental,
                    experimentId: this.batchExperimentalId,
                    enrollmentTime: this.inDate,
                    userInformationDOList: userInformationDOList
                }
                registerPatient(params).then( res => {
                    if (res.data.code === 0) {
                        this.$message.success('批量入组成功');
                        this.getData();
                    } else {
                        this.$message.error('批量入组失败');
                    }
                });
            },
            // 保存排序
            saveSort() {
                this.editVisible = false;
                fileCategoryUpdateById(this.tableData).then( res => {
                    if (res.data.code === 0) {
                        this.$message.success('保存排序成功');
                        this.getData();
                    } else {
                        this.$message.error('保存排序失败');
                    }
                });
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'page', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .table {
        width: 100%;
        font-size: 14px;
    }
    .red {
        color: #ff0000;
    }
    .mr10 {
        margin-right: 10px;
    }
    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>
