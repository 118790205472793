<template>
    <div class="shiyan">
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.username" placeholder="学生名称" class="handle-input mr10" clearable></el-input>
                <el-select v-model="query.schoolYear" clearable>
                    <el-option v-for="(item, index) in schoolYearList" :label="item" :value="item" :key="index"></el-option>
                </el-select>
                <el-select v-model="query.status">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="未开启" value="0"></el-option>
                    <el-option label="开启" value="1"></el-option>
                    <el-option label="已开展但未交组会记录" value="2"></el-option>
                    <el-option label="组会记录待审核" value="3"></el-option>
                    <el-option label="驳回" value="-1"></el-option>
                    <el-option label="审核通过" value="4"></el-option>
                    <el-option label="超期未参加" value="5"></el-option>
                    <el-option label="请假" value="6"></el-option>
                </el-select>
                <el-button style="margin-left: 10px" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <!--                <el-table-column type="selection" width="55" align="center"></el-table-column>-->
                <!--                <el-table-column prop="experiment_code" label="试验编号"></el-table-column>-->
                <el-table-column prop="title" label="学生名称">
                    <template slot-scope="scope">
                        {{ scope.row.realName }}
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="次数">
                    <template slot-scope="scope">
                        {{ scope.row.frequency }}
                    </template>
                </el-table-column>

                <el-table-column prop="schoolYear" label="计划学年"></el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status === 0">未开启</span>
                        <span v-if="scope.row.status === 1">开启</span>
                        <span v-if="scope.row.status === 2">已开展但未交组会记录</span>
                        <span v-if="scope.row.status === 3">组会记录待审核</span>
                        <span v-if="scope.row.status === -1">驳回</span>
                        <span v-if="scope.row.status === 4">审核通过</span>
                        <span v-if="scope.row.status === 5">超期未参加</span>
                        <span v-if="scope.row.status === 6">请假</span>
                    </template>
                </el-table-column>

                <!--                <el-table-column prop="createtime" label="数据创建时间"></el-table-column>-->
                <!--                <el-table-column prop="createuser" label="数据创建者"></el-table-column>-->
                <!--                <el-table-column prop="updatetime" label="数据修改时间"></el-table-column>-->
                <!--                <el-table-column prop="updateuser" label="数据修改者"></el-table-column>-->

                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == '3'" type="text" icon="el-icon-edit" @click="edit(scope.row, scope.$index)"
                            >审核</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="total"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>

            <!--            <el-upload-->
            <!--                    class="upload-demo"-->
            <!--                    action="http://192.168.0.173:40071/web/experimentalSubject/upload/"-->
            <!--                    :on-preview="handlePreview"-->
            <!--                    :on-remove="handleRemove"-->
            <!--                    :before-remove="beforeRemove"-->
            <!--                    multiple-->
            <!--                    :limit="3"-->
            <!--                    :on-exceed="handleExceed"-->
            <!--                    :file-list="fileList">-->
            <!--                <el-button size="small" type="primary">点击上传</el-button>-->
            <!--            </el-upload>-->
        </div>

        <!-- 审核组会 -->
        <el-dialog title="审核组会" :visible.sync="editVisible" width="900px">
            <el-card shadow="hover" style="margin-bottom: 10px">
                <el-row>
                    <el-col :span="8">姓名: {{ form.realName }}</el-col>
                    <el-col :span="8">学年: {{ form.schoolYear }}</el-col>
                    <el-col :span="8">次数: {{ form.frequency }}</el-col>
                </el-row>
            </el-card>
            <el-card shadow="hover" v-if="stuItem">
                <el-row style="margin-bottom: 20px">
                    <el-col :span="8">开始时间: {{ stuItem.meetingEndTime }}</el-col>
                    <el-col :span="8">结束时间: {{ stuItem.meetingStartTime }}</el-col>
                    <el-col :span="8">地址: {{ stuItem.location }}</el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <el-col>组会内容: {{ stuItem.content }}</el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <el-col>组会图片: {{ stuItem.pictureUrl }}</el-col>
                </el-row>

                <el-form ref="form" :model="form">
                    <el-form-item label="审核意见" label-width="80px">
                        <el-input type="textarea" v-model="form.checkContent" :disabled="isShow" size="mini"></el-input>
                    </el-form-item>
                    <!--                <el-form-item label="组会状态">-->
                    <!--                    <el-input v-model="form.experiment_state"></el-input>-->
                    <!--                </el-form-item>-->
                    <!--                <el-form-item label="数据状态">-->
                    <!--                    <el-input v-model="form.state"></el-input>-->
                    <!--                </el-form-item>-->
                </el-form>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="warning" @click="checkGroup(-1)">审核不通过</el-button>
                <el-button type="primary" @click="checkGroup(4)">审核通过</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    getPlanList,
    creatWeeklyMeeting,
    createStudentPlan,
    tutorGetStudentList,
    getSchoolYearList,
    getStudentByPlanId,
    getWillListByTutor,
    getById,
    getWillListByStudent,
    groupWillUpdateData
} from '@/apis/project/index';
import util from '@/utils/util';
export default {
    name: 'medical',
    components: {},
    computed: {
        projectToNode() {
            console.log(this.form);
            return this.form.experimentalStageDOList.filter((item) => {
                return item.stageName.trim() !== '' && item.startDay >= 0 && item.endDay >= 0;
            });
        }
    },
    data() {
        return {
            stuPlanVisible: false,
            studentPlanList: [],
            username: sessionStorage.getItem('username'),
            stuForm: {
                limit: 1000,
                page: 1
            },
            schoolYearList: [],
            selectStudentList: [],
            studentList: [],
            importSchoolYear: '',
            fileList: [
                {
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                },
                {
                    name: 'food2.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }
            ],
            nodeName: '',
            projectList: [],
            stuItem: undefined,
            fsValue: '',
            query: {
                schoolYear: '',
                limit: 10,
                page: 1,
                status: '',
                tutorCode: sessionStorage.getItem('username')
            },
            medicalIndex: undefined,
            total: 0,
            tableData: [],
            patientData: [],
            showPatientData: [],
            showFillData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            stuVisible: false,
            addPatientVisible: false,
            showPatientVisible: false,
            editFsqVisible: false,
            form: this.initFORM(),
            formFsq: {},
            id: -1,
            items: [],
            currentItem: [],
            visible: false,
            isShow: false,
            showFillVisible: false
        };
    },
    created() {
        this.getData();
        this.getStudentList();
    },
    mounted() {},
    methods: {
        getSchoolYear() {
            getSchoolYearList().then((res) => {
                this.schoolYearList = res.data.data;
            });
        },
        // 审核
        checkGroup(type) {
            this.stuItem.status = type;
            groupWillUpdateData(this.stuItem).then((res) => {
                console.log(res);
                if (res.data.code == 0) {
                    this.$message.success('审核成功');

                } else {
                    this.$message.error('审核失败');
                }

            });
        },

        // 获取学生列表
        getStudentList() {
            tutorGetStudentList({}).then((res) => {
                if (res.data.code === 0) {
                    this.studentList = res.data.data;
                    this.getSchoolYear();
                }
            });
        },
        // 选择学生
        handleStudentChange(ev) {
            this.selectStudentList = ev;
        },
        // 导入学生
        addStudent() {
            createStudentPlan({
                adminDOList: this.selectStudentList,
                schoolYear: this.importSchoolYear,
                username: sessionStorage.getItem('username')
            }).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('导入学生成功');
                    this.stuVisible = false;
                }
            });
        },

        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'page', val);
            this.getData();
        },

        // 分页导航
        handleSelectionPatientChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        //

        // 选择周期类型
        selectProjectType(item) {
            console.log(item, this.form);
            if (item.projectType === '1') {
                this.$set(item, 'cycle', 0);
            } else {
                this.$set(item, 'cycle', '');
            }
        },

        getProjectList() {
            let params = {
                limit: 10,
                page: 1
            };
        },

        getProjectExperimentalList(id) {
            let params = {
                experimentalId: id,
                limit: 10,
                page: 1
            };
            getList(params).then((res) => {
                //  this.projectList = res.data.data;
                if (res && res.data.code === 0) {
                    this.patientData = res.data.data;
                    console.log(this.patientData);
                } else {
                    this.$message.error('查询用户列表失败');
                }
            });
        },

        //添加项目
        addProjectChild(index) {
            for (let item of this.form.experimentalProjectAOList) {
                if (!item['projectId']) {
                    this.$message.error('请输入项目名称');
                    return;
                }
                if (item['projectType'] === '1') {
                    if (item['nodeList'].length === 0) {
                        this.$message.error('请选择节点');
                        return;
                    }
                } else {
                    if (!item['startDay'] && item['startDay'] != '0') {
                        this.$message.error('请选择项目起始天数');
                        return;
                    }
                    if (!item['endDay'] && item['endDay'] != '0') {
                        this.$message.error('请选择项目结束天数');
                        return;
                    }
                }
            }
            // let maxNode = 0;
            // this.form.experimentalStageDOList.forEach((v) => {
            //     if (v.stageOrder > maxNode) {
            //         maxNode = v.stageOrder;
            //     }
            // })
            // this.form.experimentalStageDOList.splice(index + 1, 0, {stageName: '', stageOrder: maxNode + 1, state: 0});

            this.form.experimentalProjectAOList.splice(index + 1, 0, {
                projectName: '',
                projectType: '',
                startTime: '',
                endTime: '',
                state: 0,
                nodeList: []
            });

            //this.$set(this.form.node, index + 1, {nodeName: ''});
        },

        handleAdd() {
            this.type = 2;
            this.isShow = false;
            this.form = this.initFORM();
            this.editVisible = true;
            console.log(this.form);
        },

        handleStu() {
            this.stuVisible = true;
            console.log(this.form);
        },
        select(item, path) {
            this.medicalIndex = item;
            console.log(item);
        },
        show(row) {
            this.currentItem = row;
            this.currentItem.planType = this.currentItem.planType.toString();
            this.form = row;

            //  this.editVisible = true;
            // this.isShow = true;
            // this.getByExperimentInfo(row.experimentCode);
        },
        edit(row) {
            this.currentItem = row;
            this.form = row;
            this.isShow = false;

            getById(row.id)
                .then((res) => {
                    this.stuItem = res.data.data;
                    console.log(this.stuItem);
                    this.editVisible = true;
                })
                .catch((err) => {
                    this.$message.error('请求错误：' + err);
                });
            //  this.getByExperimentInfo(row.experimentCode);
        },

        addPatient(row) {
            this.currentItem = row;
            this.form = row;
            this.type = 1;
            this.multipleSelection = [];
            this.addPatientVisible = true;
        },

        showPatient(row) {
            this.currentItem = row;
            this.showPatientData = row.userInfromationDOList;
            this.showPatientVisible = true;
        },
        showFill(row, index) {
            console.log(row, this.currentItem);
            let params = {
                experimentalId: this.currentItem.id,
                unionId: row.unionId
            };
            getFillPlan(params).then((res) => {
                if (res.data.code === 0) {
                    this.showFillData = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
                this.showFillVisible = true;
            });
        },
        // 试验关联用户
        addPatientData() {
            this.currentItem.userInfromationDOList = this.multipleSelection;
            this.currentItem.enrollmentTime = util.formatDate.format(new Date());
            registerSingleSubject(this.currentItem).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('关联用户成功');
                } else {
                    this.$message.error(res.data.msg);
                }
                this.addPatientVisible = false;
            });
        },
        editFsq(row) {
            this.formFsq = row;
            this.editFsqVisible = true;
        },

        // 获取 easy-mock 的模拟数据
        getData() {
            getWillListByTutor(this.query).then((res) => {
                if (res && res.data.code === 0) {
                    if (res.data.data.content.length === 0) {
                        this.tableData = [];
                        // this.$message.error('没有查询到数据');
                    } else {
                        this.tableData = res.data.data.content;
                        this.total = res.data.data.total;
                    }
                } else {
                    this.$message.error('查询失败');
                }
            });
        },
        // 触发搜索按钮
        handleSearch() {
            this.getData();
        },
        // 删除操作
        handleDelete(row, index) {
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    let params = {
                        experimentCode: row.experimentCode
                    };
                    experimentalDelete(params).then((res) => {
                        if (res.data.code === 0) {
                            this.$message.success('删除成功');
                            this.getData();
                        } else {
                            this.$message.success('删除失败');
                        }
                    });
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = '';
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + ' ';
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
            console.log(row);
            this.getProjectExperimentalList(row.id);
        },
        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            this.$set(this.tableData, this.idx, this.form);
        },

        // 导出excel
        exportExcel() {
            console.log(this.currentItem.experimentName.replace('/', ''));
            experimentalSubjectDownload(this.currentItem.experimentCode, this.currentItem.experimentName.replace('/', ''));
            // experimentalSubjectDownload(this.currentItem.experimentCode).then(res => {
            //     if (res.data) {
            //         var a = document.createElement('a');
            //         var url = window.URL.createObjectURL(new Blob([res.data],
            //             { type: ( "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")}));
            //         a.href = url;
            //         a.download = this.currentItem.experimentName || 'file';
            //         a.click();
            //         window.URL.revokeObjectURL(url);
            //
            //     }
            // })
        },

        // 添加
        addEdit() {
            // this.form.experimentalStageDOList.forEach((value, index, arr) => {
            //     value['stageOrder'] = index;
            // });
            //  this.editVisible = false;
            creatWeeklyMeeting(this.form).then(
                (res) => {
                    if (res.data && res.data.code === 0) {
                        this.$message.success('创建计划成功');
                        this.editVisible = false;
                        this.getData();
                    } else {
                        this.$message.error('创建计划失败');
                    }
                },
                (err) => {
                    this.$message.error('创建计划失败');
                }
            );
        },

        // 验证form
        validateForm() {
            if (!this.form.experimentName) {
                this.$message.error('请输入组会名称');
                return false;
            }
            if (!this.form.unitName) {
                this.$message.error('请输入单位名称');
                return false;
            }
            if (!this.form.unitCode) {
                this.$message.error('请输入单位编码');
                return false;
            }
            if (!this.form.startTime) {
                this.$message.error('请选择试验开始时间');
                return false;
            }
            if (!this.form.endTime) {
                this.$message.error('请选择试验结束时间');
                return false;
            }

            for (let item of this.form.experimentalStageDOList) {
                console.log(item);
                if (!item['stageName']) {
                    this.$message.error('请输入节点名称');
                    return false;
                }
                if (!item['startTime']) {
                    this.$message.error('请选择节点起始时间');
                    return false;
                }
                if (!item['endTime']) {
                    this.$message.error('请选择节点结束时间');
                    return false;
                }
            }

            for (let item of this.form.experimentalProjectAOList) {
                console.log(item);
                if (!item['projectId']) {
                    this.$message.error('请输入项目名称');
                    return false;
                }
                if (item['projectType'] === '1') {
                    if (item['nodeList'].length === 0) {
                        this.$message.error('请选择节点');
                        return false;
                    }
                } else {
                    if (!item['visitStartTime']) {
                        this.$message.error('请选择项目起始时间');
                        return false;
                    }
                    if (!item['visitEndTime']) {
                        this.$message.error('请选择项目结束时间');
                        return false;
                    }
                }
            }

            return true;
        },

        initFORM() {
            return {
                title: '',
                planType: '1',
                schoolYear: '',
                strDate: '',
                endDate: '',
                limit: 10,
                page: 1,
                state: 0,
                content: '',
                checkContent: ''
            };
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.shiyan /deep/ .el-input__inner:focus {
    background: #409eff;
    color: #ffffff;
    border-top-color: #409eff;
}

.shiyan /deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.row-bottom {
    border-bottom: 1px solid #eee;
    padding-top: 10px;
}

.selectNode /deep/ .el-select.el-select--mini {
    width: 300px;
}
</style>
