import { render, staticRenderFns } from "./RolePage.vue?vue&type=template&id=7ee47dad&scoped=true&"
import script from "./RolePage.vue?vue&type=script&lang=js&"
export * from "./RolePage.vue?vue&type=script&lang=js&"
import style0 from "./RolePage.vue?vue&type=style&index=0&id=7ee47dad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ee47dad",
  null
  
)

export default component.exports