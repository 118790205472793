<template>
    <div>
        <div class="crumbs"></div>
        <div class="container">
            <div class="handle-box">
                <!-- <el-input v-model="query.title" placeholder="计划名称" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button> -->
                <el-button type="primary" style="float: right" @click="handleAdd" v-if="detailVisible === true && type == 1"
                    >创建计划</el-button
                >
                <template v-if="detailVisible === false">
                    <el-button type="primary" style="float: right" @click="detailVisible = true">返回</el-button>
                    <el-button
                        type="primary"
                        style="float: right; margin-right: 20px"
                        v-if="tableData.length > 0 && type == 1"
                        @click="handleStu"
                        >导入学生</el-button
                    >
                </template>
            </div>
            <div v-if="detailVisible === true">
                <el-table
                    :data="firstData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column prop="schoolYear" label="计划学年"></el-table-column>
                    <el-table-column prop="planType" label="计划类型">
                        <template slot-scope="scope">
                            {{
                                scope.row.planType == 0
                                    ? '组会计划'
                                    : scope.row.planType == '1'
                                    ? '面谈计划'
                                    : scope.row.planType == '2'
                                    ? '问卷调查计划'
                                    : ''
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="strDate" label="开始时间">
                        <template slot-scope="scope">
                            {{ scope.row.strDate && scope.row.strDate.split(' ')[0] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="结束时间">
                        <template slot-scope="scope">
                            {{ scope.row.strDate && scope.row.endDate.split(' ')[0] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="getDetail(scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-table :data="tableData" border class="table" v-else>
                <el-table-column prop="content" label="计划描述"></el-table-column>
                <el-table-column prop="schoolYear" label="计划学年"></el-table-column>
                <el-table-column prop="strDate" label="开始时间">
                    <template slot-scope="scope">
                        {{ scope.row.strDate && scope.row.strDate.split(' ')[0] }}
                    </template>
                </el-table-column>
                <el-table-column prop="endDate" label="结束时间">
                    <template slot-scope="scope">
                        {{ scope.row.strDate && scope.row.endDate.split(' ')[0] }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="realName" label="导师名称"></el-table-column>
                <el-table-column prop="schoolYear" label="学年"></el-table-column>
                <el-table-column prop="interviewStartTime" label="面谈开始时间"></el-table-column>
                <el-table-column prop="interviewName" label="面谈学生姓名"></el-table-column>
                <el-table-column prop="interviewContent" label="面谈内容"></el-table-column> -->
                <el-table-column label="操作" width="180" align="center" v-if="type == 5">
                    <template slot-scope="scope">
                        <el-button type="text" @click="lookdetails(scope.row)">查看</el-button>
                        <!-- <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
                        <!-- <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)"
                            >删除</el-button
                        > -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :page-size="query.pageSize"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>
        <!-- 编辑弹出框 -->
        <el-dialog title="创建计划" :visible.sync="editVisible" width="1000px">
            <!-- <el-form ref="form" :model="form" label-width="130px">
                <el-form-item label="面谈开始时间">
                    <el-date-picker v-model="form.interviewStartTime"></el-date-picker>
                </el-form-item>
                <el-form-item label="面谈内容">
                    <el-input type="textarea" v-model="form.interviewContent"></el-input>
                </el-form-item>

                <el-form-item label="面谈学生">
                    <el-select v-model="form.interviewUserId">
                        <el-option v-for="(item, index) in studentList" :key="index" :value="item.id" :label="item.nickname"></el-option>
                    </el-select>
                </el-form-item>
            </el-form> -->
            <el-form ref="forms" :model="forms">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="计划名称" label-width="80px">
                            <el-input v-model="forms.title" size="mini"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="计划描述" label-width="80px">
                            <el-input type="textarea" v-model="forms.content" size="mini"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="开始时间" label-width="110px">
                            <el-date-picker
                                v-model="forms.strDate"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                size="mini"
                                style="width: 140px"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="结束时间" label-width="110px">
                            <el-date-picker
                                v-model="forms.endDate"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 140px"
                                size="mini"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!-- <el-col :span="6">
                        <el-form-item label="计划类型" label-width="80px">
                            <el-select v-model="form.planType" size="mini">
                                <el-option label="组会计划" value="0"></el-option>
                                <el-option label="面谈计划" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="6">
                        <el-form-item label="计划学年" label-width="80px">
                            <el-select v-model="forms.schoolYear">
                                <el-option :label="nowSchoolYear + '年上学期'" :value="nowSchoolYear + '年上学期'"></el-option>
                                <el-option :label="nowSchoolYear + '年下学期'" :value="nowSchoolYear + '年下学期'"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!--                <el-form-item label="组会状态">-->
                <!--                    <el-input v-model="form.experiment_state"></el-input>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="数据状态">-->
                <!--                    <el-input v-model="form.state"></el-input>-->
                <!--                </el-form-item>-->
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 导入学生-->
        <el-dialog title="导入学生" :visible.sync="stuVisible" width="1000px">
            <div style="padding-bottom: 10px">学年：{{ importSchoolYear }}</div>
            <!-- <div style="padding: 10px">
                学年：{{ importSchoolYear }}
                <el-select v-model="importSchoolYear" size="mini">
                    <el-option :label="item" :value="item" v-for="(item, index) in schoolYearList" :key="index"></el-option>
                </el-select>
            </div> -->

            <div style="overflow: auto">

                <vxe-table
                    align="center"
                    height="300"
                    show-overflow
                    highlight-hover-row
                    resizable
                    ref="xTable2"
                    row-id="username"
                    @checkbox-change="handleStudentChange"
                    :sort-config="{trigger: 'cell'}"
                    :data="studentList">
                    <vxe-column type="seq" width="60"></vxe-column>
                    <vxe-column type="checkbox" width="60"></vxe-column>
                    <vxe-column field="username" title="学号"></vxe-column>
                    <vxe-column field="nickname" title="姓名"></vxe-column>

                </vxe-table>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="stuVisible = false">取 消</el-button>
                <el-button type="primary" @click="addStudent">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="查看" :visible.sync="lookVisible" width="1000px">
            <div style="overflow: auto">
                <el-table style="max-height: 500px; overflow: auto" :data="looktList" border class="table">
                    <el-table-column prop="interviewName" label="姓名"></el-table-column>
                    <el-table-column prop="" label="开始时间">
                        <template slot-scope="scope">
                            {{ scope.row.interviewStartTime && scope.row.interviewStartTime.split(' ')[0] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="结束时间">
                        <template slot-scope="scope">
                            {{ scope.row.interviewEndTime && scope.row.interviewEndTime.split(' ')[0] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <span>
                                {{
                                    scope.row.status == 0
                                        ? '未开启'
                                        : scope.row.status == 1
                                        ? '开启'
                                        : scope.row.status == 2
                                        ? '完成'
                                        : scope.row.status == 3
                                        ? '超期完成'
                                        : ''
                                }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="lookVisible = false">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Sortable from 'sortablejs';
import {
    interviewGetPageList,
    tutorGetStudentList,
    getPlanFirst,
    planGetById,
    getSchoolYearList,
    getInterviewPageList,
    interviewcreateStudentPlan,
    creatWeeklyMeeting
} from '@/apis/project/index';
export default {
    name: 'dict',
    data() {
        return {
            query: {
                page: 1,
                // "identityState": 0,
                limit: 10,
                username: sessionStorage.getItem('username')
            },
            lookVisible: false,
            type: sessionStorage.getItem('roleid'),
            ids: '',
            importSchoolYear: '',
            schoolYearList: [],
            nowSchoolYear: new Date().getFullYear(),
            stuVisible: false,
            tableData: [],
            firstData: [],
            detailVisible: true, // true 显示一级
            total: 0,
            multipleSelection: [],
            delList: [],
            studentList: [],
            looktList: [],
            editVisible: false,
            pageTotal: 0,
            visibleType: 0,
            forms: this.initFORM(),
            form: {
                interviewContent: '',
                interviewName: '',
                interviewStartTime: '',
                username: sessionStorage.getItem('username'),
                location: '',
                interviewMode: ''
            },
            idx: -1,
            id: -1
        };
    },
    created() {
        this.getPlanFirst();
    },

    mounted() {},
    methods: {
        //查看面谈计划
        getInterviewPageLists(ids) {
            let parmas = {
                username: sessionStorage.getItem('username'),
                planManagementId: ids
            };
            getInterviewPageList(parmas).then((res) => {
                if (res.data.code === 0) {
                    this.looktList = res.data.data;
                    // this.total = res.data.data.total;
                } else {
                    this.$message.error('查询失败，请稍候再试');
                }
            });
        },
        lookdetails(row) {
            this.lookVisible = true;
            this.getInterviewPageLists(row.id);
        },
        // 选择学生
        handleStudentChange(ev) {
            this.selectStudentList = ev.records;
        },
        // 导入学生
        addStudent() {
            interviewcreateStudentPlan({
                planId: this.ids,
                studentList: this.$refs.xTable2.getCheckboxRecords(),
                schoolYear: this.importSchoolYear,
                username: sessionStorage.getItem('username')
            }).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('导入学生成功');
                    this.stuVisible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },

        getSchoolYear() {
            getSchoolYearList().then((res) => {
                this.schoolYearList = res.data.data;
            });
        },
        // 导入学生
        handleStu() {
            this.getStudentList();
            this.stuVisible = true;
        },
        initFORM() {
            return {
                title: '',
                planType: 1,
                schoolYear: '',
                strDate: '',
                endDate: '',
                limit: 10,
                page: 1,
                state: 0,
                content: ''
            };
        },
        getDetail(row) {
            this.ids = row.id;
            this.importSchoolYear = row.schoolYear;
            this.detailVisible = false;
            planGetById(row.id).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.data.planManagement;
                    this.detailVisible = false;
                } else {
                    this.$message.error('查询失败');
                }
            });
        },

        // 获取学生列表
        getStudentList() {
            tutorGetStudentList({}).then((res) => {
                if (res.data.code === 0) {
                    console.log(2877, res.data.data);
                    this.studentList = res.data.data;
                    this.getSchoolYear();
                }
            });
        },

        drag() {
            const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
            console.log(el);
            Sortable.create(el, {
                disabled: !this.tableData,
                ghostClass: 'sortable-ghost',
                setData: function (dataTransfer) {
                    dataTransfer.setData('Text', '');
                },
                onEnd: (evt) => {
                    // const targetRow = this.tableData.splice(evt.oldIndex, 1)[0];
                    // this.tableData.splice(evt.newIndex, 0, targetRow);
                    console.log(this.tableData, evt);
                }
            });
        },
        // 获取 easy-mock 的模拟数据
        getData() {
            interviewGetPageList(this.query).then((res) => {
                if (res && res.data.code === 0) {
                    this.tableData = res.data.data.content;
                    this.total = res.data.total;
                } else {
                    this.$message.error('查询失败');
                }
                console.log(this.tableData);
            });
        },

        getPlanFirst() {
            getPlanFirst({ limit: this.query.limit, page: this.query.page, planType: 1 }).then((res) => {
                if (res.data.code === 0) {
                    this.firstData = res.data.data.content;
                    this.total = res.data.data.total;
                } else {
                    this.$message.error('查询失败，请稍候再试');
                }
            });
        },

        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, 'pageIndex', 1);
            this.getData();
        },

        getRowKey(row) {
            return row.id + row.categoryName;
        },
        // 删除操作
        handleDelete(index, row) {
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    fileCategoryDelete(row.id).then((res) => {
                        if (res.data.code === 0) {
                            this.getData();
                            this.$message.success('删除成功');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },

        //添加子级
        addChild() {
            this.$set(this.form.children, this.form.children.length, { categoryName: '' });
        },

        // 删除子级
        removeChild(index) {
            console.log(this.form.children[index]);
            if (this.form.children[index].id) {
                this.$set(this.form.children[index], 'isDel', 1);
            } else {
                this.$delete(this.form.children, index);
            }
        },

        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = '';
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + ' ';
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },

        // 向上
        handleUp(row) {
            console.log(row);
            let index = row.sort;
            if (index === 0) {
                return;
            }

            let tempOption = this.tableData[index - 1];
            this.$set(this.tableData, index - 1, this.tableData[index]);
            this.$set(this.tableData, index, tempOption);
            this.tableData = this.tableData.map((item, index) => {
                item.sort = index;
                return item;
            });
        },
        handleChildUp(row, index) {
            console.log(row);
            if (index === 0) {
                return;
            }

            let tempOption = this.form.children[index - 1];
            this.$set(this.form.children, index - 1, this.form.children[index]);
            this.$set(this.form.children, index, tempOption);
            // this.form.children = this.form.children.map((item, index) => {
            //     if (item) {
            //         item.sort = index;
            //         return item;
            //     }
            //
            // });
        },

        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            console.log(this.form);
            this.visibleType = 0;
            this.editVisible = true;
        },

        // 添加
        handleAdd() {
            this.visibleType = 1;
            this.form = {
                categoryName: '',
                children: [{ categoryName: '' }]
            };
            this.editVisible = true;
        },

        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            creatWeeklyMeeting(this.forms).then(
                (res) => {
                    if (res.data && res.data.code === 0) {
                        this.$message.success('创建计划成功');
                        this.editVisible = false;
                        this.getPlanFirst();
                    } else {
                        this.$message.error('创建计划失败');
                    }
                },
                (err) => {
                    this.$message.error('创建计划失败');
                }
            );
            // fileCategoryUpdateById([this.form]).then((res) => {
            //     if (res.data.code === 0) {
            //         this.$message.success('修改成功');
            //         this.getData();
            //     } else {
            //         this.$message.error('修改失败');
            //     }
            // });
        },
        // 保存排序
        saveSort() {
            this.editVisible = false;
            fileCategoryUpdateById(this.tableData).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('保存排序成功');
                    this.getData();
                } else {
                    this.$message.error('保存排序失败');
                }
            });
        },
        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'pageIndex', val);
            this.getData();
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
    height: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
