<template>
    <div class="shiyan">
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.title" placeholder="学术名称" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <!--                <el-table-column type="selection" width="55" align="center"></el-table-column>-->
                <!--                <el-table-column prop="experiment_code" label="试验编号"></el-table-column>-->
                <el-table-column prop="realName" label="姓名"></el-table-column>

                <el-table-column prop="college" label="学院名称"></el-table-column>
                <el-table-column prop="collegeCode" label="学院编码"></el-table-column>
                <el-table-column prop="content" label="学术内容"></el-table-column>
                <el-table-column prop="country" label="国家名称"></el-table-column>
                <el-table-column prop="countryCode" label="国家代码"></el-table-column>
                <el-table-column prop="cultureArrangement" label="培养层次"></el-table-column>
                <el-table-column prop="cultureType" label="培养类型"></el-table-column>
                <el-table-column prop="grade" label="年级"></el-table-column>
                <el-table-column prop="jobNumber" label="导师工号"></el-table-column>

                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="showPlanDetail(scope.row, scope.$index)">查看</el-button>

<!--                        <el-button type="text" icon="el-icon-edit" @click="getPlanDetail(scope.row, scope.$index)">编辑</el-button>-->
                        <!--                        <el-button-->
                        <!--                                 :disabled="scope.row.experimentState === 2"-->
                        <!--                                type="text"-->
                        <!--                                icon="el-icon-edit"-->
                        <!--                                @click="edit(scope.row, scope.$index)"-->
                        <!--                        >编辑</el-button>-->
                        <el-button type="text" style="color: orangered" icon="el-icon-delete" @click="handleDelete(scope.row, scope.$index)"
                            >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="total"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>

            <!--            <el-upload-->
            <!--                    class="upload-demo"-->
            <!--                    action="http://192.168.0.173:40071/web/experimentalSubject/upload/"-->
            <!--                    :on-preview="handlePreview"-->
            <!--                    :on-remove="handleRemove"-->
            <!--                    :before-remove="beforeRemove"-->
            <!--                    multiple-->
            <!--                    :limit="3"-->
            <!--                    :on-exceed="handleExceed"-->
            <!--                    :file-list="fileList">-->
            <!--                <el-button size="small" type="primary">点击上传</el-button>-->
            <!--            </el-upload>-->
        </div>

        <!--        批量修改日期-->

        <el-dialog title="批量修改日期" :visible.sync="batchVisible" width="800px">
            <el-row :gutter="10">
                <el-col :span="8">
                    选择周几：
                    <el-select v-model="week" size="mini">
                        <el-option v-for="(item, index) in weekArr" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="8">
                    开始时间：
                    <el-time-select
                        size="mini"
                        v-model="startTime"
                        :picker-options="{
                            start: '08:30',
                            step: '00:15',
                            end: '18:30'
                        }"
                        placeholder="选择时间"
                    >
                    </el-time-select>
                </el-col>
                <el-col :span="8">
                    结束时间：
                    <el-time-select
                        size="mini"
                        v-model="endTime"
                        :picker-options="{
                            start: '08:30',
                            step: '00:15',
                            end: '18:30'
                        }"
                        placeholder="选择时间"
                    >
                    </el-time-select>
                </el-col>
            </el-row>

            <el-row :gutter="10" style="margin-top: 10px">
                <el-col :span="8">
                    选择学年：
                    <el-select v-model="importSchoolYear" size="mini">
                        <el-option :label="item" :value="item" v-for="(item, index) in schoolYearList" :key="index"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="8">
                    地址：
                    <el-input size="mini" v-model="location"></el-input>
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button @click="batchVisible = false">取 消</el-button>
                <el-button type="primary" @click="batchEdit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 导入学生-->
        <el-dialog title="导入学生" :visible.sync="stuVisible" width="1000px">
            <div style="padding: 10px">
                学年：
                <el-select v-model="importSchoolYear" size="mini">
                    <el-option :label="item" :value="item" v-for="(item, index) in schoolYearList" :key="index"></el-option>
                </el-select>
                <!--                   <el-select v-model="importSchoolYear" size="mini">-->
                <!--                       <el-option label="组会计划" value="0"></el-option>-->
                <!--                       <el-option label="面谈计划" value="1"></el-option>-->
                <!--                   </el-select>-->
            </div>

            <div style="overflow: auto">
                <el-table
                    ref="multipleTable"
                    @selection-change="handleStudentChange"
                    style="max-height: 500px; overflow: auto"
                    :data="studentList"
                    border
                    class="table"
                >
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="username" label="学号"></el-table-column>
                    <el-table-column prop="nickname" label="姓名"></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="stuVisible = false">取 消</el-button>
                <el-button type="primary" @click="addStudent">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 导入学生-->
        <el-dialog title="参加组会学生列表" :visible.sync="stuPlanVisible" width="1000px">
            <el-card style="margin-bottom: 10px">
                <el-form ref="form" :model="form">
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="开始时间">
                                <el-date-picker
                                    type="datetime"
                                    placeholder="选择日期"
                                    v-model="form.meetingStartTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="结束时间">
                                <el-date-picker
                                    type="datetime"
                                    placeholder="选择日期"
                                    v-model="form.meetingEndTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="地址">
                                <el-input v-model="form.location"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-card>
                <div style="overflow: auto">
                    <el-table
                        ref="multipleTable"
                        @selection-change="handleStudentChange"
                        style="max-height: 500px; overflow: auto"
                        :data="studentPlanList"
                        border
                        class="table"
                    >
                        <el-table-column type="selection" width="55"> </el-table-column>
                        <el-table-column prop="username" label="学号"></el-table-column>
                        <el-table-column prop="nickname" label="姓名"></el-table-column>
                        <el-table-column prop="telephone" label="号码"></el-table-column>
                        <el-table-column prop="meetingStartTime" label="开始时间"></el-table-column>
                        <el-table-column prop="meetingEndTime" label="结束时间"></el-table-column>
                        <el-table-column prop="location" label="地址"></el-table-column>
                    </el-table>
                </div>
            </el-card>

            <span slot="footer" class="dialog-footer">
                <el-button @click="stuPlanVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateGroup">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 查看学术详情-->
        <el-dialog title="学术详情" :visible.sync="showVisible" width="600px">
            <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="姓名">
                    <span>{{ currentItem.realName }}</span>
                </el-form-item>

                <el-form-item label="报告题目">
                    <span>{{ currentItem.reportTitle }}</span>
                </el-form-item>
                <el-form-item label="报告时间">
                    <span>{{ currentItem.reportTime }}</span>
                </el-form-item>
                <el-form-item label="学术内容">
                    <span>{{ currentItem.content }}</span>
                </el-form-item>
                <el-form-item label="电话">
                    <span>{{ currentItem.telphone }}</span>
                </el-form-item>
                <el-form-item label="分数">
                    <span>{{ currentItem.score }}</span>
                </el-form-item>

                <el-form-item label="所属学科">
                    <span>{{ currentItem.subject }}</span>
                </el-form-item>
                <el-form-item label="学科代码">
                    <span>{{ currentItem.subjectCode }}</span>
                </el-form-item>
                <el-form-item label="学院名称">
                    <span>{{ currentItem.college }}</span>
                </el-form-item>
                <el-form-item label="学院编码">
                    <span>{{ currentItem.collegeCode }}</span>
                </el-form-item>

                <el-form-item label="国家名称">
                    <span>{{ currentItem.country }}</span>
                </el-form-item>
                <el-form-item label="国家代码">
                    <span>{{ currentItem.countryCode }}</span>
                </el-form-item>
                <el-form-item label="培养层次">
                    <span>{{ currentItem.cultureArrangement }}</span>
                </el-form-item>
                <el-form-item label="培养类型">
                    <span>{{ currentItem.cultureType }}</span>
                </el-form-item>
                <el-form-item label="年级">
                    <span>{{ currentItem.grade }}</span>
                </el-form-item>
                <el-form-item label="导师工号">
                    <span>{{ currentItem.jobNumber }}</span>
                </el-form-item>
                <el-form-item label="国家名称">
                    <span>{{ currentItem.country }}</span>
                </el-form-item>
                <el-form-item label="国家编码">
                    <span>{{ currentItem.countryCode }}</span>
                </el-form-item>
                <el-form-item label="省名称">
                    <span>{{ currentItem.province }}</span>
                </el-form-item>
                <el-form-item label="省编码">
                    <span>{{ currentItem.provinceCode }}</span>
                </el-form-item>
                <el-form-item label="市名称">
                    <span>{{ currentItem.city }}</span>
                </el-form-item>
                <el-form-item label="市编码">
                    <span>{{ currentItem.cityCode }}</span>
                </el-form-item>
                <el-form-item label="市编码">
                    <span>{{ currentItem.cityCode }}</span>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateGroup">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    academicReportGetList,
    creatWeeklyMeeting,
    createStudentPlan,
    tutorGetStudentList,
    getSchoolYearList,
    getStudentByPlanId,
    updateGroupWill,
    academicReportDeleteById,
    academicReportGetById
} from '@/apis/project/index';
import util from '@/utils/util';
export default {
    name: 'medical',
    components: {},
    computed: {
        projectToNode() {
            console.log(this.form);
            return this.form.experimentalStageDOList.filter((item) => {
                return item.stageName.trim() !== '' && item.startDay >= 0 && item.endDay >= 0;
            });
        }
    },
    data() {
        return {
            batchVisible: false,
            week: '',
            weekArr: [
                { name: '星期一', value: '0' },
                { name: '星期二', value: '1' },
                { name: '星期三', value: '2' },
                { name: '星期四', value: '3' },
                { name: '星期五', value: '4' },
                { name: '星期六', value: '5' },
                { name: '星期天', value: '6' }
            ],
            startTime: '',
            endTime: '',
            location: '',
            stuPlanVisible: false,
            studentPlanList: [],
            username: sessionStorage.getItem('username'),
            stuForm: {
                limit: 1000,
                page: 1
            },
            schoolYearList: [],
            selectStudentList: [],
            studentList: [],
            importSchoolYear: '',
            fileList: [
                {
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                },
                {
                    name: 'food2.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }
            ],
            nodeName: '',
            projectList: [],
            fsValue: '',
            query: {
                limit: 10,
                page: 1
            },
            medicalIndex: undefined,
            total: 0,
            tableData: [],
            editVisible: false,
            stuVisible: false,
            editFsqVisible: false,
            form: this.initFORM(),
            formFsq: {},
            id: -1,
            items: [],
            currentItem: [],
            showVisible: false,
            visible: false,
            isShow: false,
            showFillVisible: false
        };
    },
    created() {
        this.getData();
        this.getStudentList();
    },
    mounted() {},
    methods: {
        batchEdit() {
            if (!this.week) {
                this.$message.error('请选择周几');
                return;
            }
            if (!this.startTime) {
                this.$message.error('请选择开始时间');
                return;
            }
            if (!this.endTime) {
                this.$message.error('请选择结束时间');
                return;
            }
            if (!this.importSchoolYear) {
                this.$message.error('请选择学年');
                return;
            }

            let params = {
                weekDay: this.week,
                startTime: this.startTime,
                endTime: this.endTime,
                schoolYear: this.importSchoolYear,
                location: this.location,
                jobNumber: sessionStorage.getItem('username')
            };

            batchUpdateTime(params).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('修改成功');
                    this.week = '';
                    this.startTime = '';
                    this.endTime = '';
                    this.importSchoolYear = '';
                    this.location = '';
                    this.batchVisible = false;
                }
            });
        },

        getSchoolYear() {
            getSchoolYearList().then((res) => {
                this.schoolYearList = res.data.data;
            });
        },

        showPlanDetail(row, index) {
            academicReportGetById(row.id).then((res) => {
                if (res.data.code === 0) {
                    this.showVisible = true;
                    this.currentItem = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },

        getPlanDetail(row, index) {
            this.form.groupWillId = row.id;
            let params = {
                planId: row.id,
                tutorCode: sessionStorage.getItem('username') === 'admin' ? null : sessionStorage.getItem('username')
            };

            getStudentByPlanId(params).then((res) => {
                this.stuPlanVisible = true;
                this.studentPlanList = res.data.data;
            });
        },

        // 获取学生列表
        getStudentList() {
            tutorGetStudentList({}).then((res) => {
                if (res.data.code === 0) {
                    console.log(res.data.data);
                    this.studentList = res.data.data;
                    this.getSchoolYear();
                }
            });
        },
        // 选择学生
        handleStudentChange(ev) {
            this.selectStudentList = ev;
            console.log(ev);
        },
        // 导入学生
        addStudent() {
            createStudentPlan({
                adminDOList: this.selectStudentList,
                schoolYear: this.importSchoolYear,
                username: sessionStorage.getItem('username')
            }).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('导入学生成功');
                    this.stuVisible = false;
                }
            });
        },

        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'page', val);
            this.getData();
        },

        // 分页导航
        handleSelectionPatientChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        //

        // 修改组会信息
        updateGroup() {
            console.log(this.form);
            let params = { ...this.form, adminDOList: this.selectStudentList, username: sessionStorage.getItem('username') };
            updateGroupWill(params).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('编辑成功');
                    this.stuPlanVisible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },

        handleBatch() {
            this.batchVisible = true;
        },

        handleStu() {
            this.stuVisible = true;
            console.log(this.form);
        },
        select(item, path) {
            this.medicalIndex = item;
            console.log(item);
        },

        edit(row) {
            this.currentItem = row;
            this.form = row;
            this.type = 1;
            this.isShow = false;
            this.editVisible = true;
            //  this.getByExperimentInfo(row.experimentCode);
        },

        addPatient(row) {
            this.currentItem = row;
            this.form = row;
            this.type = 1;
            this.multipleSelection = [];
            this.addPatientVisible = true;
        },

        // 获取 easy-mock 的模拟数据
        getData() {
            academicReportGetList({ username: sessionStorage.getItem('username'), ...this.query }).then((res) => {
                console.log(res);
                if (res && res.data.code === 0) {
                    this.tableData = res.data.data.content;
                    this.total = res.data.data.total;
                } else {
                    this.$message.error('查询失败');
                }
            });
        },
        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, 'page', 1);
            this.getData();
        },
        // 删除操作
        handleDelete(row, index) {
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    academicReportDeleteById(row.id).then((res) => {
                        if (res.data.code === 0) {
                            this.$message.success('删除成功');
                            this.getData();
                        } else {
                            this.$message.success('删除失败');
                        }
                    });
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = '';
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + ' ';
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
            console.log(row);
            this.getProjectExperimentalList(row.id);
        },
        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            this.$set(this.tableData, this.idx, this.form);
        },

        // 导出excel

        // 添加
        addEdit() {
            // this.form.experimentalStageDOList.forEach((value, index, arr) => {
            //     value['stageOrder'] = index;
            // });
            //  this.editVisible = false;
            creatWeeklyMeeting(this.form).then(
                (res) => {
                    if (res.data && res.data.code === 0) {
                        this.$message.success('创建计划成功');
                        this.editVisible = false;
                        this.getData();
                    } else {
                        this.$message.error('创建计划失败');
                    }
                },
                (err) => {
                    this.$message.error('创建计划失败');
                }
            );
        },

        initFORM() {
            return {
                meetingStartTime: '',
                meetingEndTime: '',
                location: ''
            };
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.shiyan /deep/ .el-input__inner:focus {
    background: #409eff;
    color: #ffffff;
    border-top-color: #409eff;
}

.shiyan /deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.row-bottom {
    border-bottom: 1px solid #eee;
    padding-top: 10px;
}

.selectNode /deep/ .el-select.el-select--mini {
    width: 300px;
}

.demo-table-expand {
    font-size: 0;
}
/deep/ .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
/deep/ .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
