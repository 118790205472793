<template>
    <div class="notice">
      <div style="padding: 20px;">
        <el-row  class="notice-item" :gutter="20">
          <el-col :style="{'width': ItemWidth}">
            <div class="column" @click="selectItem(1)">
              <el-col class="notice-img" :lg="12" :md="12" :xl="24" :xs="24">
                <img src="../../../assets/img/tongzhi.svg"  />
              </el-col>
              <el-col class="notice-desc" :lg="12" :md="12" :xl="24" :xs="24">
                <div class="notice-number">{{noticeNumber}}</div>
                <div>最新通告</div>
              </el-col>
            </div>
          </el-col>
          <el-col :style="{'width': ItemWidth}">
            <div class="column"  @click="selectItem(2)">
              <el-col class="notice-img" :lg="12" :md="12" :xl="24" :xs="24">
                <img src="../../../assets/img/guanli.svg" />
              </el-col>
              <el-col class="notice-desc" :lg="12" :md="12" :xl="24" :xs="24">
                <div class="notice-number">{{projectNumber}}</div>
                <div>我的项目</div>
              </el-col>
            </div>
          </el-col>
          <el-col :style="{'width': ItemWidth}">
            <div class="column" @click="selectItem(3)">
              <el-col class="notice-img" :lg="12" :md="12" :xl="24" :xs="24">
                <img src="../../../assets/img/pingjia.svg" />
              </el-col>
              <el-col class="notice-desc" :lg="12" :md="12" :xl="24" :xs="24">
                <div class="notice-number">{{evaluateNumber}}</div>
                <div>我的评价</div>
              </el-col>
            </div>
          </el-col>
          <el-col :style="{'width': ItemWidth}" v-if="addShow === '1'">
            <div class="column" @click="selectItem(5)">
              <el-col class="notice-img" :lg="12" :md="12" :xl="24" :xs="24">
                <img src="../../../assets/img/jianyi.svg"  />
              </el-col>
              <el-col class="notice-desc" :lg="12" :md="12" :xl="24" :xs="24">
                <div class="notice-number">{{offerNumber}}</div>
                <div>最新建议</div>
              </el-col>
            </div>
          </el-col>
          <el-col  :style="{'width': ItemWidth}">
            <div class="column" @click="selectItem(4)">
              <el-col class="notice-img" :lg="12" :md="12" :xl="24" :xs="24">
                <img src="../../../assets/img/renwu.svg" />
              </el-col>
              <el-col class="notice-desc" :lg="12" :md="12" :xl="24" :xs="24">
                <div class="notice-number">0</div>
                <div>个人中心</div>
              </el-col>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
</template>

<script>
export default {
  name: 'Notice',
  components: {

  },
  data () {
    return {
      currentItem: 1,
      noticeNumber: 0,
      offerNumber: 0,
      projectNumber: 0,
      evaluateNumber: 0,
      ItemWidth: sessionStorage.getItem('unitLevel') !== '1' ? '25%' : '20%',
      addShow: sessionStorage.getItem('unitLevel')
    }
  },
  methods: {
    selectItem (id) {
      this.currentItem = id;
    }
  }
}
</script>

<style lang="scss" scoped>
  .notice{
    padding: 10px;
  }
  .notice-item{

    .notice-img{
      /*float: left;*/
      /*overflow: hidden;*/
      /*height: 0;*/
      /*width: 50%;*/
      /*padding-bottom: 6rem;*/
      /*text-align: center;*/
      img {
        padding: .3rem;
        width: 100%;
        max-width: 120px;
        box-sizing: border-box;
      }
    }
    .notice-desc{
      /*float:right;*/
      /*flex: 1;*/
      text-align: center;

      div{
        font-size: 22px;
        color: #333;
        height: 100%;
      }
      .notice-number{
        font-size: 28px;
        color: tomato;
        height: 100%;
      }
    }

   .column {
      display: flex;
      align-items: center;
      justify-content: center;
      /*margin-left: 40px;*/
     height: 124px;
      background-color: #fff;
      border-radius: 10px;
      border: none;
      position: relative;
      margin-bottom: 30px;
     cursor: pointer;
      box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
    }
  }

  .el-row {
    /*margin-bottom: 20px;*/
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  /*.row-bg {*/
  /*  padding: 10px 0;*/
  /*  background-color: #f9fafc;*/
  /*}*/

  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

</style>
