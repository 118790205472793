<template>
    <div class="shiyan">
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.department" placeholder="反馈科室" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                <!--                <template v-if="username === 'admin'">-->
                <!--                    <el-button type="primary" style="float: right;"  @click="handleAdd">创建计划</el-button>-->
                <!--                    <el-button type="primary" style="float: right;" v-if="tableData.length > 0"  @click="handleStu">导入学生</el-button>-->
                <!--                </template>-->
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <!--                <el-table-column type="selection" width="55" align="center"></el-table-column>-->
                <!--                <el-table-column prop="experiment_code" label="试验编号"></el-table-column>-->
                <el-table-column prop="name" label="学号"></el-table-column>
                <el-table-column prop="department" label="科室"></el-table-column>
                <el-table-column prop="content" label="反馈内容"></el-table-column>
                <el-table-column prop="pictureUrl" label="反馈图片" width="200px">
                    <template slot-scope="scope">
                        <img :src="scope.row.pictureUrl" style="width: 200px;"/>
                    </template>
                </el-table-column>
                <!--                <el-table-column prop="createtime" label="数据创建时间"></el-table-column>-->
                <!--                <el-table-column prop="createuser" label="数据创建者"></el-table-column>-->
                <!--                <el-table-column prop="updatetime" label="数据修改时间"></el-table-column>-->
                <!--                <el-table-column prop="updateuser" label="数据修改者"></el-table-column>-->

                <el-table-column label="操作" width="180" align="center">
                    <!-- <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="getPlanDetail(scope.row, scope.$index)">修改</el-button>
                        <el-button type="text" @click="getPlanDetail(scope.row, scope.$index)">查看详情</el-button>
                        <el-button type="text" icon="el-icon-delete" @click="getPlanDetail(scope.row, scope.$index)">删除</el-button>
                    </template> -->
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="total"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>

            <!--            <el-upload-->
            <!--                    class="upload-demo"-->
            <!--                    action="http://192.168.0.173:40071/web/experimentalSubject/upload/"-->
            <!--                    :on-preview="handlePreview"-->
            <!--                    :on-remove="handleRemove"-->
            <!--                    :before-remove="beforeRemove"-->
            <!--                    multiple-->
            <!--                    :limit="3"-->
            <!--                    :on-exceed="handleExceed"-->
            <!--                    :file-list="fileList">-->
            <!--                <el-button size="small" type="primary">点击上传</el-button>-->
            <!--            </el-upload>-->
        </div>

        <!-- 导入学生-->
        <el-dialog title="导入学生" :visible.sync="stuVisible" width="1000px">
            <div style="padding: 10px">
                学年：
                <el-select v-model="importSchoolYear" size="mini">
                    <el-option :label="item" :value="item" v-for="(item, index) in schoolYearList" :key="index"></el-option>
                </el-select>
                <!--                   <el-select v-model="importSchoolYear" size="mini">-->
                <!--                       <el-option label="组会计划" value="0"></el-option>-->
                <!--                       <el-option label="面谈计划" value="1"></el-option>-->
                <!--                   </el-select>-->
            </div>

            <div style="overflow: auto">
                <el-table
                    ref="multipleTable"
                    @selection-change="handleStudentChange"
                    style="max-height: 500px; overflow: auto"
                    :data="studentList"
                    border
                    class="table"
                >
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="username" label="学号"></el-table-column>
                    <el-table-column prop="nickname" label="姓名"></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="stuVisible = false">取 消</el-button>
                <el-button type="primary" @click="addStudent">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 导入学生-->
        <el-dialog title="参加组会学生列表" :visible.sync="stuPlanVisible" width="1000px">
            <el-card style="margin-bottom: 10px">
                <el-form ref="form" :model="form">
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="开始时间">
                                <el-date-picker
                                    type="datetime"
                                    placeholder="选择日期"
                                    v-model="form.meetingStartTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="结束时间">
                                <el-date-picker
                                    type="datetime"
                                    placeholder="选择日期"
                                    v-model="form.meetingEndTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="地址">
                                <el-input v-model="form.location"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-card>
                <div style="overflow: auto">
                    <el-table
                        ref="multipleTable"
                        @selection-change="handleStudentChange"
                        style="max-height: 500px; overflow: auto"
                        :data="studentPlanList"
                        border
                        class="table"
                    >
                        <el-table-column type="selection" width="55"> </el-table-column>
                        <el-table-column prop="username" label="学号"></el-table-column>
                        <el-table-column prop="nickname" label="姓名"></el-table-column>
                        <el-table-column prop="telephone" label="号码"></el-table-column>
                        <el-table-column prop="meetingStartTime" label="开始时间"></el-table-column>
                        <el-table-column prop="meetingEndTime" label="结束时间"></el-table-column>
                        <el-table-column prop="location" label="地址"></el-table-column>
                    </el-table>
                </div>
            </el-card>

            <span slot="footer" class="dialog-footer">
                <el-button @click="stuPlanVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateGroup">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getAnonymousLetterList } from '@/apis/project/index';
import util from '@/utils/util';
export default {
    name: 'medical',
    components: {},
    computed: {
        projectToNode() {
            console.log(this.form);
            return this.form.experimentalStageDOList.filter((item) => {
                return item.stageName.trim() !== '' && item.startDay >= 0 && item.endDay >= 0;
            });
        }
    },
    data() {
        return {
            stuPlanVisible: false,
            studentPlanList: [],
            username: sessionStorage.getItem('username'),
            stuForm: {
                limit: 1000,
                page: 1
            },
            schoolYearList: [],
            selectStudentList: [],
            studentList: [],
            importSchoolYear: '',
            fileList: [
                {
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                },
                {
                    name: 'food2.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }
            ],
            nodeName: '',
            projectList: [],
            fsValue: '',
            query: {
                limit: 10,
                page: 1,
                state: 0,
                department: ''
            },
            medicalIndex: undefined,
            total: 0,
            tableData: [],
            patientData: [],
            showPatientData: [],
            showFillData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            stuVisible: false,
            addPatientVisible: false,
            showPatientVisible: false,
            editFsqVisible: false,
            form: this.initFORM(),
            formFsq: {},
            id: -1,
            items: [],
            currentItem: [],
            visible: false,
            isShow: false,
            showFillVisible: false
        };
    },
    created() {
        this.getData();
    },
    mounted() {},
    methods: {
        getSchoolYear() {
            getSchoolYearList().then((res) => {
                this.schoolYearList = res.data.data;
            });
        },
        getPlanDetail(row, index) {
            this.form.groupWillId = row.id;
            let params = {
                planId: row.id,
                tutorCode: sessionStorage.getItem('username') === 'admin' ? null : sessionStorage.getItem('username')
            };

            getStudentByPlanId(params).then((res) => {
                this.stuPlanVisible = true;
                this.studentPlanList = res.data.data;
            });
        },

        // 获取学生列表
        getStudentList() {
            tutorGetStudentList({}).then((res) => {
                if (res.data.code === 0) {
                    console.log(res.data.data);
                    this.studentList = res.data.data;
                    this.getSchoolYear();
                }
            });
        },
        // 选择学生
        handleStudentChange(ev) {
            this.selectStudentList = ev;
            console.log(ev);
        },
        // 导入学生
        addStudent() {
            createStudentPlan({
                adminDOList: this.selectStudentList,
                schoolYear: this.importSchoolYear,
                username: sessionStorage.getItem('username')
            }).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('导入学生成功');
                    this.stuVisible = false;
                }
            });
        },

        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'page', val);
            this.getData();
        },

        // 分页导航
        handleSelectionPatientChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        //

        // 选择周期类型
        selectProjectType(item) {
            console.log(item, this.form);
            if (item.projectType === '1') {
                this.$set(item, 'cycle', 0);
            } else {
                this.$set(item, 'cycle', '');
            }
        },

        // 修改组会信息
        updateGroup() {
            console.log(this.form);
            let params = { ...this.form, adminDOList: this.selectStudentList, username: sessionStorage.getItem('username') };
            updateGroupWill(params).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('编辑成功');
                    this.stuPlanVisible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },

        getProjectList() {
            let params = {
                limit: 10,
                page: 1
            };
        },

        handleAdd() {
            this.type = 2;
            this.isShow = false;
            this.form = this.initFORM();
            this.editVisible = true;
            console.log(this.form);
        },

        handleStu() {
            this.stuVisible = true;
            console.log(this.form);
        },
        select(item, path) {
            this.medicalIndex = item;
            console.log(item);
        },
        show(row) {
            this.currentItem = row;
            this.currentItem.planType = this.currentItem.planType.toString();
            this.form = row;

            //  this.editVisible = true;
            // this.isShow = true;
            // this.getByExperimentInfo(row.experimentCode);
        },
        edit(row) {
            this.currentItem = row;
            this.form = row;
            this.type = 1;
            this.isShow = false;
            this.editVisible = true;
            //  this.getByExperimentInfo(row.experimentCode);
        },

        addPatient(row) {
            this.currentItem = row;
            this.form = row;
            this.type = 1;
            this.multipleSelection = [];
            this.addPatientVisible = true;
        },

        showPatient(row) {
            this.currentItem = row;
            this.showPatientData = row.userInfromationDOList;
            this.showPatientVisible = true;
        },
        showFill(row, index) {
            console.log(row, this.currentItem);
            let params = {
                experimentalId: this.currentItem.id,
                unionId: row.unionId
            };
            getFillPlan(params).then((res) => {
                if (res.data.code === 0) {
                    this.showFillData = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
                this.showFillVisible = true;
            });
        },
        // 试验关联用户
        addPatientData() {
            this.currentItem.userInfromationDOList = this.multipleSelection;
            this.currentItem.enrollmentTime = util.formatDate.format(new Date());
            registerSingleSubject(this.currentItem).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('关联用户成功');
                } else {
                    this.$message.error(res.data.msg);
                }
                this.addPatientVisible = false;
            });
        },
        editFsq(row) {
            this.formFsq = row;
            this.editFsqVisible = true;
        },

        // 获取 easy-mock 的模拟数据
        getData() {
            getAnonymousLetterList(this.query).then((res) => {
                if (res && res.data.code === 0) {
                    this.tableData = res.data.data.content;
                    this.total = res.data.data.total;
                } else {
                    this.$message.error('查询失败');
                }
            });
        },
        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, 'page', 1);
            this.getData();
        },
        // 删除操作
        handleDelete(row, index) {
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    let params = {
                        experimentCode: row.experimentCode
                    };
                    experimentalDelete(params).then((res) => {
                        if (res.data.code === 0) {
                            this.$message.success('删除成功');
                            this.getData();
                        } else {
                            this.$message.success('删除失败');
                        }
                    });
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = '';
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + ' ';
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
            console.log(row);
            this.getProjectExperimentalList(row.id);
        },
        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            this.$set(this.tableData, this.idx, this.form);
        },

        // 导出excel
        exportExcel() {
            console.log(this.currentItem.experimentName.replace('/', ''));
            experimentalSubjectDownload(this.currentItem.experimentCode, this.currentItem.experimentName.replace('/', ''));
            // experimentalSubjectDownload(this.currentItem.experimentCode).then(res => {
            //     if (res.data) {
            //         var a = document.createElement('a');
            //         var url = window.URL.createObjectURL(new Blob([res.data],
            //             { type: ( "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")}));
            //         a.href = url;
            //         a.download = this.currentItem.experimentName || 'file';
            //         a.click();
            //         window.URL.revokeObjectURL(url);
            //
            //     }
            // })
        },

        // 添加
        addEdit() {
            // this.form.experimentalStageDOList.forEach((value, index, arr) => {
            //     value['stageOrder'] = index;
            // });
            //  this.editVisible = false;
            creatWeeklyMeeting(this.form).then(
                (res) => {
                    if (res.data && res.data.code === 0) {
                        this.$message.success('创建计划成功');
                        this.editVisible = false;
                        this.getData();
                    } else {
                        this.$message.error('创建计划失败');
                    }
                },
                (err) => {
                    this.$message.error('创建计划失败');
                }
            );
        },

        // 验证form
        validateForm() {
            if (!this.form.experimentName) {
                this.$message.error('请输入组会名称');
                return false;
            }
            if (!this.form.unitName) {
                this.$message.error('请输入单位名称');
                return false;
            }
            if (!this.form.unitCode) {
                this.$message.error('请输入单位编码');
                return false;
            }
            if (!this.form.startTime) {
                this.$message.error('请选择试验开始时间');
                return false;
            }
            if (!this.form.endTime) {
                this.$message.error('请选择试验结束时间');
                return false;
            }

            for (let item of this.form.experimentalStageDOList) {
                console.log(item);
                if (!item['stageName']) {
                    this.$message.error('请输入节点名称');
                    return false;
                }
                if (!item['startTime']) {
                    this.$message.error('请选择节点起始时间');
                    return false;
                }
                if (!item['endTime']) {
                    this.$message.error('请选择节点结束时间');
                    return false;
                }
            }

            for (let item of this.form.experimentalProjectAOList) {
                console.log(item);
                if (!item['projectId']) {
                    this.$message.error('请输入项目名称');
                    return false;
                }
                if (item['projectType'] === '1') {
                    if (item['nodeList'].length === 0) {
                        this.$message.error('请选择节点');
                        return false;
                    }
                } else {
                    if (!item['visitStartTime']) {
                        this.$message.error('请选择项目起始时间');
                        return false;
                    }
                    if (!item['visitEndTime']) {
                        this.$message.error('请选择项目结束时间');
                        return false;
                    }
                }
            }

            return true;
        },

        initFORM() {
            return {
                meetingStartTime: '',
                meetingEndTime: '',
                location: ''
            };
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.shiyan /deep/ .el-input__inner:focus {
    background: #409eff;
    color: #ffffff;
    border-top-color: #409eff;
}

.shiyan /deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.row-bottom {
    border-bottom: 1px solid #eee;
    padding-top: 10px;
}

.selectNode /deep/ .el-select.el-select--mini {
    width: 300px;
}
</style>
