import {req} from '../api.http'

// 获取试验列表
export const getSubjectList = (params = {}) => {
  return  req('post', '/web/experimentalSubject/getSubjectList', params)

}

// 根据试验code获取详细信息
export const getByExperimentCode = (code = {}) => {
  return req('get', `/web/experimentalSubject/getByExperimentCode/${code}`)

}

// 根据试验code查询相关阶段
export const getExperimentCodeStage = (experimentCode = {}) => {
  return req('get', `/web/experimentalStage/getByExperimentCode/${experimentCode}`)

}


// 插入试验主体及有关信息
export const insertData = (params = {}) => {
  return req('post', `/web/experimentalSubject/insertData`, params)

}

// 根据试验获取用户列表
export const getListBySubject = (params = {}) => {
  return req('post', `/web/userInfromation/getListBySubject`, params)

}
// 试验主体数据和选择的患者集合
export const registerSingleSubject = (params = {}) => {
  return req('post', `/web/fillInPlan/registerSingleSubject`, params)

}

// 删除试验主体及有关信息
export const experimentalDelete = (params = {}) => {
  return req('post', `/web/experimentalSubject/delete`, params)

}

// 用户填报记录
export const getFillPlan = (params = {}) => {
  return req('post', `/web/patientFillPlan/getFillPlan`, params)

}


// 用户填报记录系详情
export const getDetailList = (params = {}) => {
  return req('post', `/web/patientFillPlan/getDetailList`, params)

}

// 用户填报记录
export const getProjectDetail = (fillInPlanId = {}) => {
  return req('get', `/web/patientFillPlan/getDetail/${fillInPlanId}`)

}

// 实验导出excel
export const experimentalSubjectDownload = (experimentCode, name) => {
  return req('request', `/web/experimentalSubject/download/${experimentCode}/${name}`)

}

// 患者导出excel
export const fillInPlanDownload = (params) => {
  return req('request', `/web/fillInPlan/download/${params.unionId}/${params.experimentCode}/${params.fileName}`)

}



